import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React from 'react';

import Logo from '@shared/icons/Logo/Logo';
import container from '@core/di';
import Config from '@core/config';

import styles from './Footer.styles';

type FooterProps = WithStyles<typeof styles> & {};

const FooterComponent: React.FC<FooterProps> = ({ classes }) => {
  const config = container.get<Config>(Config.diToken);
  const version = config.get().version ? config.get().version : '1.0.0';

  return (
    <footer className={classes.root}>
      <div className={classes.leftPart}>
        <div className={classes.logoWrap}>
          <span className={classes.powerText}>Powered by</span>
          <Logo classes={{ root: classes.logo }} />
        </div>

        <div className={classes.text}>All rights reserved</div>

        <div className={classes.text}>Version {version}</div>
      </div>

      <div className={classes.rightPart}>
        <a href="https://mirialiti.com/privacy" target="_blank" className={classes.links}>
          Privacy Policy
        </a>

        <a
          href="https://mirialiti.com/terms-%26-conditions"
          target="_blank"
          className={classes.links}
        >
          Terms & Conditions
        </a>
      </div>
    </footer>
  );
};

export const Footer = withStyles(styles)(FooterComponent);
