import { injectable } from 'inversify';
import { computed, observable } from 'mobx';

import container from '@core/di';
import ExperiencesService from '@shared/services/experiences';
import { ListRequestParams, Pagination } from '@shared/types/services';
import Experience from '@shared/models/experience';
import { Id } from '@shared/types/common';
import { ExportResourceType } from '@shared/constants/export';

@injectable()
export default class ExperiencesStore {
  static diToken = Symbol('experiences-store');

  private service = container.get<ExperiencesService>(ExperiencesService.diToken);
  @observable private _list: Array<Experience> = [];
  @observable private _pagination: Pagination;
  @observable loading = {
    list: false,
  };
  @observable error = {
    itemGet: false,
  };

  @computed get list() {
    return this._list;
  }

  @computed get pagination() {
    return this._pagination;
  }

  getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.getList(params);

      this._list = list;
      this._pagination = pagination;
    } finally {
      this.loading.list = false;
    }
  };

  getExperience = async (id: Id) => {
    this.error.itemGet = false;

    try {
      const experience = await this.service.getExperience(id);

      return experience;
    } catch {
      this.error.itemGet = true;
    }
  };

  exportExperiences = (resource: ExportResourceType) => {
    return this.service.exportExperiences(resource);
  };
}
