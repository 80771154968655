import SurveyQuestion, { SurveyQuestionDTO } from './question';

export interface SurveyCoordinates {
  lat: number;
  lng: number;
}

export enum SurveyStatus {
  draft = 'draft',
  active = 'active',
  completed = 'completed',
}

export const SURVEY_STATUS_TO_COMPLETE_SURVEY = [SurveyStatus.active];

export const SURVEY_STATUS_TO_PUBLISH_SURVEY = [SurveyStatus.draft, SurveyStatus.completed];

export interface SurveyDTO {
  id: string;
  interviewsAmount: number;
  interviewsCount: number;
  link?: string;
  title: string;
  teamMembersCount: number;
  interviewersCount: number;
  coordinates: Array<SurveyCoordinates>;
  description: string;
  useLocation: boolean;
  questions: Array<SurveyQuestionDTO>;
  isDraft?: boolean;
  status: SurveyStatus;
}

export default class Survey {
  id: string;
  interviewsAmount: number;
  interviewsCount: number;
  link?: string;
  title: string;
  teamMembersCount: number;
  teamMembersAmount: number;
  interviewersCount: number;
  coordinates: Array<SurveyCoordinates>;
  description: string;
  useLocation: boolean;
  questions: Array<SurveyQuestion>;
  isDraft?: boolean;
  status: SurveyStatus;

  constructor(dto: Partial<SurveyDTO>) {
    this.update(dto);
  }

  get asJson(): SurveyDTO {
    return {
      id: this.id,
      interviewsAmount: this.interviewsAmount,
      interviewsCount: this.interviewsCount,
      link: this.link,
      title: this.title,
      teamMembersCount: this.teamMembersCount,
      interviewersCount: this.interviewersCount,
      coordinates: this.coordinates,
      description: this.description,
      useLocation: this.useLocation,
      questions: this.questions,
      isDraft: this.isDraft,
      status: this.status,
    };
  }

  update(newData: Partial<SurveyDTO>) {
    const { questions = [], ...otherData } = { ...this.asJson, ...newData };

    Object.assign(this, otherData);

    this.questions = questions.map((question) => new SurveyQuestion(question));

    return this.asJson;
  }
}
