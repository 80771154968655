import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import container from '@core/di';
import PrivateModule from '@modules/Private';
import PublicModule from '@modules/Public';
import ErrorHandler from '@shared/components/ErrorHandler';
import { NotificationType, showNotification } from '@shared/components/Notification';
import { SystemUserRole } from '@shared/models/system-user';
import AuthStore from '@shared/stores/auth';
import BusinessOwnersStore from '@shared/stores/business-owners';
import { browser } from '@shared/utils/browser';
import { CSS_VARIABLES, getScreenHeight } from '@shared/utils/layout';

import styles from './App.styles';

export interface AppProps extends WithStyles<typeof styles>, RouteComponentProps {}

@hot(module)
@observer
class App extends React.Component<AppProps> {
  @observable private appCrashed: boolean;
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private businessOwnersStore = container.get<BusinessOwnersStore>(BusinessOwnersStore.diToken);

  componentDidMount() {
    this.setCSSAppHeightVariable();
    window.addEventListener('resize', debounce(this.setCSSAppHeightVariable, 10));

    if (browser?.name === 'ie') {
      showNotification(
        'For full experience we recommend you change your Internet Explorer browser for another.',
        NotificationType.warning
      );
    }
    const { currentUser } = this.authStore;
    if (currentUser && currentUser.roles.includes(SystemUserRole.businessOwner)) {
      this.businessOwnersStore.initialize(currentUser.id);
    }
  }

  private setCSSAppHeightVariable = () => {
    const doc = document.documentElement;

    doc.style.setProperty(CSS_VARIABLES.appHeight, `${getScreenHeight()}px`);
  };

  componentDidCatch() {
    this.appCrashed = true;
  }

  render() {
    const { classes } = this.props;

    if (this.appCrashed) {
      return <ErrorHandler />;
    }

    const { loggedIn } = this.authStore;

    return (
      <Typography component="div" className={classes.root}>
        <Route path="/" component={loggedIn ? PrivateModule : PublicModule} />
      </Typography>
    );
  }
}

export default withStyles(styles)(withRouter(App));
