import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

import container from '@core/di';
import TeamsService from '@shared/services/teams';
import { ListRequestParams, Pagination } from '@shared/types/services';
import Team, { TeamDTO } from '@shared/models/team';
import Project from '@shared/models/project';
import { Id } from '@shared/types/common';
import Member from '@shared/models/team/member';
import { ExportResourceType } from '@shared/constants/export';

@injectable()
export default class TeamsStore {
  static diToken = Symbol('teams-store');

  private service = container.get<TeamsService>(TeamsService.diToken);
  @observable private _list: Array<Team> = [];
  @observable private _projects: Array<Project> = [];
  @observable private _members: Array<Member> = [];
  @observable private _mobileUsers: Array<Member> = [];
  @observable private _pagination: Pagination;
  @observable private _projectsPagination: Pagination;
  @observable private _membersPagination: Pagination;
  @observable private _mobileUsersPagination: Pagination;
  @observable loading = {
    list: false,
    team: false,
    projects: false,
    members: false,
    mobileUsers: false,
  };
  @observable error = {
    teamGet: false,
  };

  @computed get list() {
    return this._list;
  }

  @computed get projects() {
    return this._projects;
  }

  @computed get members() {
    return this._members;
  }

  @computed get mobileUsers() {
    return this._mobileUsers;
  }

  @computed get pagination() {
    return this._pagination;
  }

  @computed get projectsPagination() {
    return this._projectsPagination;
  }

  @computed get membersPagination() {
    return this._membersPagination;
  }

  @computed get mobileUsersPagination() {
    return this._mobileUsersPagination;
  }

  getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.getList(params);

      this._list = list;

      if (pagination) {
        this._pagination = pagination;
      }
    } finally {
      this.loading.list = false;
    }
  };

  get = async (teamId: Id) => {
    this.error.teamGet = false;
    this.loading.team = true;

    try {
      const team = await this.service.get(teamId);

      return team;
    } catch {
      this.error.teamGet = true;
    } finally {
      this.loading.team = false;
    }
  };

  create = async (team: TeamDTO) => {
    const data = await this.service.create(team);

    return data;
  };

  addProjects = async (teamId: Id, projectIds: Array<Id>) => {
    return this.service.addProjects(teamId, projectIds);
  };

  getProjects = async (teamId: Id, params: ListRequestParams) => {
    this.loading.projects = true;

    try {
      const { list, pagination } = await this.service.getProjects(teamId, params);

      this._projects = list;

      if (pagination) {
        this._projectsPagination = pagination;
      }
    } finally {
      this.loading.projects = false;
    }
  };

  inviteMembers = (data: { teamId: Id; userIds: Array<Id> }) => {
    return this.service.inviteMembers(data);
  };

  getMembers = async (teamId: Id, params: ListRequestParams) => {
    this.loading.members = true;

    try {
      const { list, pagination } = await this.service.getMembers(teamId, params);

      this._members = list;

      if (pagination) {
        this._pagination = pagination;
      }
    } finally {
      this.loading.members = false;
    }
  };

  getMobileUsers = async (params: ListRequestParams) => {
    this.loading.mobileUsers = true;

    try {
      const { list, pagination } = await this.service.getMobileUsers(params);

      this._mobileUsers = list;
      this._mobileUsersPagination = pagination;
    } finally {
      this.loading.mobileUsers = false;
    }
  };

  deleteTeamMember = (teamId: Id, memberId: Id) => {
    return this.service.deleteTeamMember(teamId, memberId);
  };

  exportTeam = (resource: ExportResourceType, resourceId: Id) => {
    return this.service.exportTeam(resource, resourceId);
  };

  resetList = () => {
    this._list = [];
  };

  resetProjects = () => {
    this._projects = [];
  };

  resetMembers = () => {
    this._members = [];
  };

  @action resetMobileUsers = () => {
    this._mobileUsers = [];
  };
}
