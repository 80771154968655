import container from '@core/di';
import ACCESS from '@shared/constants/access';
import ROUTES from '@shared/constants/routes';
import AuthStore from '@shared/stores/auth';

const NAVIGATION = [
  {
    name: 'Experiences',
    route: ROUTES.private.experiences,
    access: ACCESS.experiences,
  },
  { name: 'Profiles', route: ROUTES.private.profiles, access: ACCESS.profiles },
  {
    name: 'Business owners',
    route: ROUTES.private.businessOwners,
    access: ACCESS.businessOwners,
  },
  {
    name: 'Dashboard',
    route: ROUTES.private.dashboard,
    access: ACCESS.dashboard,
  },
  { name: 'Projects', route: ROUTES.private.projects, access: ACCESS.projects },
  { name: 'Teams', route: ROUTES.private.teams, access: ACCESS.teams },
  {
    name: 'User Deletion',
    route: ROUTES.private.userDeletion,
    access: ACCESS.userDeletion,
  },
];

export const getNavigationLinks = () => {
  const authStore = container.get<AuthStore>(AuthStore.diToken);

  return NAVIGATION.filter(({ access }) => access.includes(authStore.user.role)).map(
    ({ access, ...otherData }) => otherData
  );
};
