import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      maxWidth: 450,
    },
    content: {
      padding: 0,
      fontSize: 15,
    },
  });
}
