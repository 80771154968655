import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      height: 48,
      background: colors.primary,
    },
    tabs: {
      height: '100%',
      boxShadow: 'none',
      marginRight: spacing(4),
      background: colors.primary,
    },
    link: {
      color: colors.white,
    },
    indicator: {
      color: colors.white,
      borderBottom: `3px solid ${colors.white}`,
    },
  });
}
