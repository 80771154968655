import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      height: 50,
      fontSize: '11.5rem',
    },
  });
}
