import { AppFileType } from './app-file';

export enum BusinessOwnerLicenseStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum BusinessOwnerCognitoStatus {
  forceChangePassword = 'FORCE_CHANGE_PASSWORD',
}

export enum BusinessOwnerLicenseType {
  largeEnterprise = 'large_enterprise',
  smallEnterprise = 'small_enterprise',
  microEnterprise = 'micro_enterprise',
  soloPreneur = 'solo_preneur',
  communityBasedOrganization = 'community_based_organization',
  academicHigher = 'academic_higher',
  academiaK12 = 'academia_k_12',
  individual = 'individual',
}

export enum BusinessOwnerOrganizationSize {
  '<5' = '<5',
  '5-9' = '5-9',
  '10-19' = '10-19',
  '20-49' = '20-49',
  '50-99' = '50-99',
  '100-300' = '100-300',
  '>300' = '>300',
}

export enum BusinessOwnerOrganizationType {
  academicK12 = 'academic_k12',
  communityBasedOrganization = 'community_based_organization',
  cooperative = 'cooperative',
  corporation = 'corporation',
  governmental = 'governmental',
  limitedAbilityCompany = 'limited_liability_company',
  limitedPartnership = 'limited_partnership',
  nonGovernmentalOrganization = 'non_governmental_organization',
  nonprofitOrganizationLarge = 'nonprofit_organization_large',
  nonprofitOrganizationIntermediate = 'nonprofit_organization_intermediate',
  nonprofitOrganizationCommunityBased = 'nonprofit_organization_community_based',
  partnership = 'partnership',
  philanthropic = 'philanthropic',
  soleProprietorship = 'sole_proprietorship',
  university = 'university',
  other = 'other',
}

export interface BusinessOwnerAddress {
  country: string;
  state: string;
  city: string;
  street: string;
  house: string;
  zip: string;
}

export interface BusinessOwnerLicense {
  status: BusinessOwnerLicenseStatus;
  type: BusinessOwnerLicenseType;
  startDate: string;
  duration: string;
  note?: string;
}

export interface BusinessOwnerOrganization {
  id?: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  type: BusinessOwnerOrganizationType;
  size: BusinessOwnerOrganizationSize;
  address: BusinessOwnerAddress;
}

export interface BusinessOwnerLogo {
  id: string;
  type: AppFileType;
  link: string;
}

export interface BusinessOwnerBackground {
  id: string;
  type: AppFileType;
  link: string;
}

export interface BusinessOwnerImagesDTO {
  logo?: string;
  background?: string;
}

export interface BusinessOwnerDTO {
  id: string;
  email: string;
  name: string;
  license: BusinessOwnerLicense;
  organization: BusinessOwnerOrganization;
  cognitoStatus: BusinessOwnerCognitoStatus;
  logo?: BusinessOwnerLogo;
  background?: BusinessOwnerBackground;
}

export default class BusinessOwner {
  id: string;
  email: string;
  name: string;
  license: BusinessOwnerLicense;
  organization: BusinessOwnerOrganization;
  cognitoStatus: BusinessOwnerCognitoStatus;
  logo?: BusinessOwnerLogo;
  background?: BusinessOwnerBackground;

  constructor(dto: BusinessOwnerDTO) {
    this.update(dto);
  }

  get asJson(): BusinessOwnerDTO {
    return {
      id: this.id,
      email: this.email,
      name: this.name,
      license: this.license,
      organization: this.organization,
      cognitoStatus: this.cognitoStatus,
      logo: this.logo,
      background: this.background,
    };
  }

  update(newData: Partial<BusinessOwnerDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
