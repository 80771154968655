import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Button, { ButtonProps } from '@shared/components/Button';

import styles from './Message.styles';

export interface MessageConfig {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  icon?: React.ReactNode;
  buttonProps?: Partial<ButtonProps>;
}

export interface MessageProps extends WithStyles<typeof styles>, MessageConfig {}

const Message: React.FC<MessageProps> = (props) => {
  const { classes, heading, icon, subheading, buttonProps, ...otherProps } = props;

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      direction="column"
      {...otherProps}
    >
      {heading && <Grid item>{<h4 className={classes.heading}>{heading}</h4>}</Grid>}
      {icon && (
        <Grid item classes={{ root: classes.icon }}>
          {icon}
        </Grid>
      )}
      {subheading && <Grid item>{<h4 className={classes.subheading}>{subheading}</h4>}</Grid>}
      {buttonProps && <Button classes={{ root: classes.button }} {...buttonProps} />}
    </Grid>
  );
};

Message.defaultProps = {
  icon: null,
};

export default withStyles(styles)(Message);
