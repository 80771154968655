import { $enum } from 'ts-enum-util';

export const getEnumKeys = <Enum>(enumLiteral: Enum) => {
  return $enum(enumLiteral as any).getKeys() as Array<keyof Enum>;
};

type EnumValue = number | string;

export const getEnumValues = (enumLiteral) => {
  return $enum(enumLiteral).getValues() as Array<EnumValue>;
};
