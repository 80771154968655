import { injectable } from 'inversify';

import {
  ExportSuccessResponse,
  ListRequestParams,
  ListRequestResponse,
} from '@shared/types/services';
import Team, { TeamDTO } from '@shared/models/team';
import { getHTTPClient } from '@core/http-client';
import { Id } from '@shared/types/common';
import Project, { ProjectDTO } from '@shared/models/project';
import Member, { MemberDTO } from '@shared/models/team/member';
import { ExportResourceType, ExportUserRole } from '@shared/constants/export';

const $http = getHTTPClient();
const urlPrefix = '/team';

@injectable()
export default class TeamsService {
  static diToken = Symbol('teams-service');

  async getList(params: ListRequestParams) {
    const { data } = await $http.get<Array<TeamDTO> | ListRequestResponse<TeamDTO>>(urlPrefix, {
      params,
    });

    if (data instanceof Array) {
      return {
        list: data.map((dto) => new Team(dto)),
      };
    }

    return {
      list: data.data.map((dto) => new Team(dto)),
      pagination: data.meta.pagination,
    };
  }

  async get(teamId: Id) {
    const { data } = await $http.get<TeamDTO>(`${urlPrefix}/${teamId}`);

    return new Team(data);
  }

  async create(team: TeamDTO) {
    const { data } = await $http.post<TeamDTO>(urlPrefix, team);

    return new Team(data);
  }

  addProjects(teamId: Id, projectIds: Array<Id>) {
    return $http.patch(`${urlPrefix}/${teamId}`, { projectIds });
  }

  async getProjects(teamId: Id, params: ListRequestParams) {
    const { data } = await $http.get<Array<ProjectDTO> | ListRequestResponse<ProjectDTO>>(
      `${urlPrefix}/${teamId}/projects`,
      {
        params,
      }
    );

    if (data instanceof Array) {
      return {
        list: data.map((dto) => new Project(dto)),
      };
    }

    return {
      list: data.data.map((dto) => new Project(dto)),
      pagination: data.meta.pagination,
    };
  }

  inviteMembers(data: { teamId: Id; userIds: Array<Id> }) {
    return $http.post('team-invitation', data);
  }

  async getMembers(teamId: Id, params: ListRequestParams) {
    const { data } = await $http.get<Array<MemberDTO> | ListRequestResponse<MemberDTO>>(
      `${urlPrefix}/${teamId}/members`,
      {
        params,
      }
    );

    if (data instanceof Array) {
      return {
        list: data.map((dto) => new Member(dto)),
      };
    }

    return {
      list: data.data.map((dto) => new Member(dto)),
      pagination: data.meta.pagination,
    };
  }

  async getMobileUsers(params: ListRequestParams) {
    const { data } = await $http.get<ListRequestResponse<Member>>('mobile-user', {
      params,
    });

    return {
      list: data.data.map((dto) => new Member(dto)),
      pagination: data.meta.pagination,
    };
  }

  deleteTeamMember(teamId: Id, memberId: Id) {
    return $http.delete(`${urlPrefix}/${teamId}/member/${memberId}`);
  }

  async exportTeam(resource: ExportResourceType, resourceId: Id) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data', {
      resource,
      resourceId,
      export: 'team',
      type: ExportUserRole.businessOwner,
    });

    return data;
  }
}
