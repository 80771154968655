import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import BackButton, { BackButtonProps } from '@shared/components/BackButton';

import styles from './Subheader.styles';

export interface SubheaderConfig {
  backButton?: Partial<BackButtonProps>;
  additionalContent?: React.ReactNode;
}

export interface SubheaderProps extends WithStyles<typeof styles>, SubheaderConfig {}

const Subheader: React.FC<SubheaderProps> = ({ classes, backButton, additionalContent }) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
      className={classes.root}
    >
      <BackButton {...backButton} />
      {additionalContent}
    </Grid>
  );
};

export default withStyles(styles)(Subheader);
