import { injectable } from 'inversify';

import HTTPClient, { getHTTPClient } from '@core/http-client';
import { AppFileType } from '@shared/models/app-file';
import container from '@core/di';

export interface MultipartUploadFilePart {
  ETag: string;
  PartNumber: number;
}

const $http = getHTTPClient();

@injectable()
export class UploadService {
  static diToken = Symbol('upload-service');
  private httpClient = container.get<HTTPClient>(HTTPClient.diToken);

  async generateSinglePartPresignedUrl(path: string): Promise<{ id: string; link: string }> {
    const { data } = await $http.get<{ id: string; link: string }>(path);

    return data;
  }

  async uploadSinglePartFile(url: string, file: File, config: { reUploadAttemptsAmount: number }) {
    const arrayBuffer = await file.arrayBuffer();
    const instance = this.httpClient.createInstance();

    this.httpClient.retryRequest(instance, { retries: config.reUploadAttemptsAmount });

    await instance.put(url, arrayBuffer, {
      headers: { 'Content-Type': file.type, 'x-amz-content-sha256': 'UNSIGNED-PAYLOAD' },
    });
  }

  async generateMultiPartPresignedUrls<
    Response = {
      id: string;
      objectName: string;
      uploadId: string;
      urls: Array<string>;
    },
    CustomRequestData = {
      type: AppFileType;
      contentType: string;
    }
  >(
    path: string,
    baseData: { file: File },
    config: { chunkSize: number },
    customData?: CustomRequestData
  ) {
    const partsAmount = Math.ceil(baseData.file.size / config.chunkSize);
    const res = await $http.post<Response>(path, { parts: partsAmount, ...customData });

    return res.data;
  }

  async uploadMultiPartFile(
    file: File,
    urls: Array<string>,
    config: { fileChunkSize: number; reUploadAttemptsAmount: number }
  ): Promise<Array<MultipartUploadFilePart>> {
    const instance = this.httpClient.createInstance();

    delete instance.defaults.headers.put['Content-Type'];

    this.httpClient.retryRequest(instance, { retries: config.reUploadAttemptsAmount });

    const promises: Array<Promise<any>> = [];

    urls.forEach((url, index) => {
      const start = index * config.fileChunkSize;
      const end = (index + 1) * config.fileChunkSize;
      const blob = index < urls.length ? file.slice(start, end) : file.slice(start);

      promises.push(instance.put(urls[index], blob));
    });

    const resParts = await Promise.all(promises);

    return resParts.map((part, index) => ({
      ETag: JSON.parse(part.headers.etag),
      PartNumber: index + 1,
    }));
  }

  async completeMultiPartUpload(path: string, data: { [key: string]: any }) {
    const res = await $http.post(path, data);

    return res.data;
  }
}
