// tslint:disable:variable-name
import { ProfileDTO } from '@shared/models/profile';

interface EntityDTO {
  id: number;
  name: string;
  position: number;
}

export enum ExperienceWorkplaceType {
  college = 1,
  government = 2,
  privateSector = 3,
  independent = 4,
  other = 5,
}

export enum ExperienceType {
  good = 'good',
  great = 'great',
  normal = 'normal',
  bad = 'bad',
  ugly = 'ugly',
}

interface ExperienceReportingMode {
  id: number;
  name: string;
  fixedName?: string;
}

interface ExperienceLocationType {
  id: number;
  name: string;
}

interface ExperienceUser {
  id: number;
  name: string;
  email: string;
  photoFile?: string;
}

export interface ExperienceFile {
  url: string;
  value: string;
}

export interface ExperienceDTO {
  date: string;
  experience: ExperienceType;
  hasKeywords: boolean;
  hasLocation: boolean;
  hasNotes: boolean;
  hasProfile: boolean;
  id: number;
  name: string;
  user: ExperienceUser;
  userProfile: ProfileDTO['profile'];
  latitude: number;
  longitude: number;
  what3Words: string;
  photoFile: ExperienceFile;
  photo_2File: ExperienceFile;
  photo_3File: ExperienceFile;
  audioFile: ExperienceFile;
  videoFile: ExperienceFile;
  pdfFile: ExperienceFile;
  pdf_2File: ExperienceFile;
  pdf_3File: ExperienceFile;
  historicalInstitutionName?: string;
  historicalWorkplaceId?: ExperienceWorkplaceType;
  otherPersonAge?: EntityDTO;
  otherPersonGender?: EntityDTO;
  otherPersonRace?: EntityDTO;
  otherPersonIncomeRange?: EntityDTO;
  otherPersonReligion?: EntityDTO;
  otherPersonIndustry?: EntityDTO;
  otherPersonOrientation?: EntityDTO;
  otherPersonPosition?: EntityDTO;
  otherPersonPositionCustom?: string;
  keywordsValues?: Array<string>;
  locationType?: ExperienceLocationType;
  notes?: string;
  reportingMode?: ExperienceReportingMode;
}

export default class Experience {
  date: string;
  experience: ExperienceType;
  hasKeywords: boolean;
  hasLocation: boolean;
  hasNotes: boolean;
  hasProfile: boolean;
  id: number;
  name: string;
  user: ExperienceUser;
  userProfile: ProfileDTO['profile'];
  what3Words: string;
  latitude: number;
  longitude: number;
  photoFile: ExperienceFile;
  photo_2File: ExperienceFile;
  photo_3File: ExperienceFile;
  audioFile: ExperienceFile;
  videoFile: ExperienceFile;
  pdfFile: ExperienceFile;
  pdf_2File: ExperienceFile;
  pdf_3File: ExperienceFile;
  historicalInstitutionName?: string;
  historicalWorkplaceId?: ExperienceWorkplaceType;
  otherPersonAge?: EntityDTO;
  otherPersonGender?: EntityDTO;
  otherPersonRace?: EntityDTO;
  otherPersonIncomeRange?: EntityDTO;
  otherPersonReligion?: EntityDTO;
  otherPersonIndustry?: EntityDTO;
  otherPersonOrientation?: EntityDTO;
  otherPersonPosition?: EntityDTO;
  otherPersonPositionCustom?: string;
  keywordsValues?: Array<string>;
  locationType?: ExperienceLocationType;
  notes?: string;
  reportingMode?: ExperienceReportingMode;

  constructor(dto: ExperienceDTO) {
    this.update(dto);
  }

  get asJson(): ExperienceDTO {
    return {
      id: this.id,
      hasKeywords: this.hasKeywords,
      hasLocation: this.hasLocation,
      hasNotes: this.hasNotes,
      hasProfile: this.hasProfile,
      user: this.user,
      date: this.date,
      experience: this.experience,
      name: this.name,
      userProfile: this.userProfile,
      latitude: this.latitude,
      longitude: this.longitude,
      reportingMode: this.reportingMode,
      locationType: this.locationType,
      what3Words: this.what3Words,
      notes: this.notes,
      historicalInstitutionName: this.historicalInstitutionName,
      historicalWorkplaceId: this.historicalWorkplaceId,
      otherPersonAge: this.otherPersonAge,
      otherPersonGender: this.otherPersonGender,
      otherPersonRace: this.otherPersonRace,
      otherPersonIncomeRange: this.otherPersonIncomeRange,
      otherPersonReligion: this.otherPersonReligion,
      otherPersonIndustry: this.otherPersonIndustry,
      otherPersonOrientation: this.otherPersonOrientation,
      otherPersonPosition: this.otherPersonPosition,
      otherPersonPositionCustom: this.otherPersonPositionCustom,
      keywordsValues: this.keywordsValues,
      photoFile: this.photoFile,
      photo_2File: this.photo_2File,
      photo_3File: this.photo_3File,
      audioFile: this.audioFile,
      videoFile: this.videoFile,
      pdfFile: this.pdfFile,
      pdf_2File: this.pdf_2File,
      pdf_3File: this.pdf_3File,
    };
  }

  update(newData: Partial<ExperienceDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
