import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@shared/stores/layout';

export default function styles({ spacing, palette: { colors }, breakpoints }: Theme) {
  return createStyles({
    root: {
      height: 48,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tabsRoot: {
      height: '100%',
      marginRight: spacing(3),
    },
    tabsContainer: {
      height: '100%',
    },
    link: {
      color: colors.black,
      fontSize: 13,

      [breakpoints.down(BREAKPOINT.mobile)]: {
        fontSize: 11,
      },
    },
    activeLink: {
      color: colors.green,
    },
    indicator: {
      color: colors.green,
      borderBottom: `3px solid ${colors.green}`,
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      root: {
        height: 42,
      },
      link: {
        fontSize: 12,
      },
    },
  });
}
