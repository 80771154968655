import { injectable } from 'inversify';

import { getHTTPClient } from '@core/http-client';
import { What3WordsDTO, What3WordsModel } from '@shared/models/what3words';
import { FourSquareDTO, FourSquareModel } from '@shared/models/foursquare';

const https = getHTTPClient();

type LatLng = string | number;

export interface Coordinates {
  lat: LatLng;
  lng: LatLng;
}

@injectable()
export class LocationService {
  static diToken = Symbol('locations-service');

  private foursquareUrlPrefix = '/foursquare';
  private what3WordsUrlPrefix = '/what3words';

  private computeUrl = (baseUrl: string, options?: { public?: boolean }) => {
    return `${baseUrl}${options?.public ? '/guest' : ''}`;
  };

  private getNormalizedCoordinatesOrder = (coordinates: Coordinates) => {
    return [coordinates.lat, coordinates.lng];
  };

  async getFourSquareLocations(
    params: {
      coordinates: Coordinates;
      radiusMeters: number;
    },
    options?: { public?: boolean }
  ) {
    const url = this.computeUrl(this.foursquareUrlPrefix, { public: options?.public });
    const { coordinates, radiusMeters } = params;

    const { data } = await https.get<{ response: { venues: Array<FourSquareDTO> } }>(url, {
      params: {
        route: '/venues/search',
        ll: this.getNormalizedCoordinatesOrder(coordinates),
        radius: radiusMeters,
      },
    });

    return data.response.venues.map((x) => new FourSquareModel(x));
  }

  async getWhat3WordsLocation(
    params: {
      coordinates: Coordinates;
    },
    options?: { public?: boolean }
  ) {
    const url = this.computeUrl(this.what3WordsUrlPrefix, { public: options?.public });
    const { coordinates } = params;
    const normalizedCoordinates = [coordinates.lat, coordinates.lng];

    const { data } = await https.get<What3WordsDTO>(url, {
      params: {
        route: 'convert-to-3wa',
        language: 'en',
        format: 'json',
        coordinates: normalizedCoordinates,
      },
    });

    return new What3WordsModel(data);
  }
}
