import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { Link, useHistory } from 'react-router-dom';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cx from 'classnames';
import { LocationDescriptorObject, Path } from 'history';

import styles from './Tabs.styles';

type Route = Path | LocationDescriptorObject;

interface LinkConfig {
  name: string;
  route: Route;
}

export interface TabsProps extends WithStyles<typeof styles> {
  links: Array<LinkConfig>;
  actions?: React.ReactNode;
}

const Tabs: React.FC<TabsProps> = ({ classes, links, actions }) => {
  const history = useHistory();
  const getPathname = React.useCallback(
    (route: Route) => {
      const pathname = typeof route === 'string' ? route : (route.pathname as string);

      return pathname;
    },
    [history.location.pathname]
  );

  const activeLink = React.useMemo(() => {
    const link = links.find(({ route }) => {
      return history.location.pathname.startsWith(getPathname(route));
    });

    return link ? getPathname(link.route) : '';
  }, [history.location.pathname, links]);

  return (
    <Paper elevation={4} className={classes.root}>
      <MuiTabs
        value={activeLink}
        variant="scrollable"
        scrollButtons="off"
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsContainer,
          indicator: classes.indicator,
        }}
      >
        {links.map((link) => (
          <Tab
            key={link.name}
            value={getPathname(link.route)}
            label={link.name}
            component={Link}
            to={link.route}
            classes={{
              root: cx(classes.link, {
                [classes.activeLink]: getPathname(link.route) === history.location.pathname,
              }),
            }}
          />
        ))}
      </MuiTabs>
      {actions}
    </Paper>
  );
};

export default withStyles(styles)(Tabs);
