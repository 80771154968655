import Axios from 'axios';
import { injectable } from 'inversify';

import { getHTTPClient } from '@core/http-client';
import BusinessOwner, {
  BusinessOwnerDTO,
  BusinessOwnerImagesDTO,
} from '@shared/models/business-owner';
import {
  ExportSuccessResponse,
  ListRequestParams,
  ListRequestResponse,
} from '@shared/types/services';
import { Id } from '@shared/types/common';
import { ExportResourceType, ExportUserRole } from '@shared/constants/export';
import { DashboardDataDTO } from '@shared/models/dashboard';

const $http = getHTTPClient();
const urlPrefix = '/business-owner';

@injectable()
export default class BusinessUsersService {
  static diToken = Symbol('business-users-service');

  private getDashboardDataURL = () => {
    return `/dashboard`;
  };

  async getList(params: ListRequestParams) {
    const { data } = await $http.get<ListRequestResponse<BusinessOwner>>(urlPrefix, {
      params,
    });

    return {
      list: data.data.map((dto) => new BusinessOwner(dto)),
      pagination: data.meta.pagination,
    };
  }

  create(data: BusinessOwnerDTO) {
    return $http.post(urlPrefix, data);
  }

  edit(id: Id, data: BusinessOwnerDTO) {
    return $http.put(`${urlPrefix}/${id}`, data);
  }

  update(id: Id, data: BusinessOwnerImagesDTO) {
    return $http.patch(`${urlPrefix}/${id}`, data);
  }

  async get(id: Id) {
    const { data } = await $http.get(`${urlPrefix}/${id}`);

    return new BusinessOwner(data);
  }

  async exportBushinessOwners(resource: ExportResourceType) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data', {
      resource,
      export: 'business_owners',
      type: ExportUserRole.admin,
    });

    return data;
  }

  async getDashboardData() {
    const { data } = await $http.get<DashboardDataDTO>(this.getDashboardDataURL());

    return data;
  }

  async getLogoUploadLink() {
    const { data } = await $http.get<{ id: string; link: string }>(`/file/upload-link/image`);

    return data;
  }

  async uploadLogo(link: string, logo: ArrayBuffer, extension: File['type']) {
    const instance = Axios.create();

    return instance.put(link, logo, {
      headers: {
        'Content-Type': extension,
        'x-amz-content-sha256': 'UNSIGNED-PAYLOAD',
      },
    });
  }
}
