import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ boxShadow, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      height: 48,
      backgroundColor: colors.white,
      boxShadow: boxShadow.containers.tetriary,
    },
  });
}
