import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import styles from './Notification.styles';

export enum NotificationType {
  info,
  error,
  warning,
}

export interface NotificationProps extends WithStyles<typeof styles> {
  autoHideDuration?: number | null;
  className?: string;
}

interface NotificationState {
  message: React.ReactNode;
  type: NotificationType;
  notificationProps: Partial<NotificationProps>;
  open: boolean;
}

const defaultProps = {
  autoHideDuration: 5000,
  className: '',
};

let showNotificationFn;
let hideNotificationFn;
let isOpenNotificationFn;

export function showNotification(
  message: React.ReactNode,
  type: NotificationType = NotificationType.info,
  notificationProps?: Partial<NotificationProps>
) {
  showNotificationFn(message, type, notificationProps);
}

export function hideNotification() {
  hideNotificationFn();
}

export function isOpenNotification(): boolean {
  return isOpenNotificationFn();
}

class Notification extends React.Component<NotificationProps, NotificationState> {
  readonly state = {
    message: '',
    type: NotificationType.info,
    notificationProps: defaultProps,
    open: false,
  };

  private icons = {
    [NotificationType.info]: null,
    [NotificationType.error]: <WarningIcon className={this.props.classes.errorIcon} />,
    [NotificationType.warning]: <WarningIcon className={this.props.classes.warningIcon} />,
  };

  componentDidMount() {
    showNotificationFn = this.open;
    hideNotificationFn = this.close;
    isOpenNotificationFn = this.isOpenNotification;
  }

  private isOpenNotification = () => this.state.open;

  private open = (
    message: React.ReactNode,
    type: NotificationType = NotificationType.info,
    notificationProps: NotificationProps
  ) => {
    message = (
      <div className={this.props.classes.snackbarContentWrapper}>
        {this.icons[type]}
        {message}
      </div>
    );

    this.setState({
      message,
      type,
      notificationProps: {
        ...defaultProps,
        ...notificationProps,
      },
      open: true,
    });
  };

  private close = () => {
    this.setState({ notificationProps: defaultProps, open: false });
  };

  render() {
    const { classes } = this.props;
    const { message, type, notificationProps, open } = this.state;
    const { autoHideDuration } = notificationProps;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={autoHideDuration}
        open={open}
        TransitionComponent={Fade}
        onClose={this.close}
      >
        <SnackbarContent
          className={classes[type]}
          message={message}
          action={
            <IconButton disableRipple className={classes.closeButton} onClick={this.close}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(Notification);
