export interface TeamDTO {
  title: string;
  id: string;
  membersCount: string;
  projectsCount: string;
}

export default class Team {
  title: string;
  id: string;
  membersCount: string;
  projectsCount: string;

  constructor(dto: Partial<TeamDTO>) {
    this.update(dto);
  }

  get asJson(): TeamDTO {
    return {
      title: this.title,
      id: this.id,
      membersCount: this.membersCount,
      projectsCount: this.projectsCount,
    };
  }

  update(newData: Partial<TeamDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
