import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ palette: { colors }, spacing }: Theme) {
  return createStyles({
    root: {
      width: '100%',
      height: '74px',
      backgroundColor: colors.white,
      padding: spacing(3, 4),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    leftPart: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    logoWrap: {
      display: 'flex',
      gap: '5px',
    },
    logo: {
      height: 12,
      width: 62,
    },
    powerText: {
      color: colors.grey6,
      fontSize: 12,
      fontWeight: 400,
    },
    text: {
      color: colors.grey,
      fontSize: 12,
      fontWeight: 400,
    },
    rightPart: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    links: {
      color: colors.greyLight13,
      fontSize: 12,
      fontWeight: 500,

      '&:hover': {
        color: colors.green,
      },
    },
    '@media (min-width: 360px)': {
      links: {
        fontSize: 14,
      },
    },
    '@media (min-width: 600px)': {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(5, 6),
      },
      logo: {
        height: 14,
        width: 72,
      },
      powerText: {
        fontSize: 14,
      },
      rightPart: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '32px',
      },
      links: {
        fontSize: 16,
      },
    },
    '@media (min-width: 780px)': {
      root: {
        padding: spacing(7, 8),
      },
      leftPart: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '36px',
      },
    },
    '@media (min-width: 960px)': {
      rightPart: { gap: '64px' },
    },
  });
}
