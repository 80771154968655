import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Lazyload from '@shared/components/LazyLoad';
import ROUTES from '@shared/constants/routes';
import container from '@core/di';
import Config from '@core/config';

const AuthWrapper = React.lazy(() => import('./components/AuthWrapper'));
const Survey = React.lazy(() => import('./submodules/Survey'));

import styles from './Public.styles';

export interface PublicProps extends WithStyles<typeof styles>, RouteComponentProps {}

export interface SurveyRouteParams {
  token: string;
}

class Public extends React.Component<PublicProps> {
  private config = container.get<Config>(Config.diToken);

  private renderSurvey = (props: RouteComponentProps<SurveyRouteParams>) => {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={this.config.get().RECAPTCHA_SITE_KEY}>
        <Survey {...props} />
      </GoogleReCaptchaProvider>
    );
  };

  private get routes(): Array<RouteProps> {
    const { surveys } = ROUTES.public;

    return [
      { path: `${surveys}/:token`, render: this.renderSurvey },
      { path: '/', component: AuthWrapper },
    ];
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Lazyload>
          <Switch>
            {this.routes.map((routeProps) => (
              <Route key={String(routeProps.path)} {...routeProps} />
            ))}
            <Redirect to={ROUTES.public.login} />
          </Switch>
        </Lazyload>
      </div>
    );
  }
}

export default withStyles(styles)(Public);
