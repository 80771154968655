import { injectable } from 'inversify';

import {
  ChangeStatusRequestParams,
  ExportSuccessResponse,
  LegacyListRequestResponse,
  ListRequestParams,
  SearchUsersRequestParams,
} from '@shared/types/services';
import Profile, { ProfileDTO } from '@shared/models/profile';
import { getHTTPClient, HttpClientType } from '@core/http-client';
import { denormalizeLegacyPagination, normalizeLegacyPagination } from '@shared/utils/services';
import { ExportResourceType, ExportUserRole } from '@shared/constants/export';

const $http = getHTTPClient(HttpClientType.backend);

@injectable()
export default class ProfilesService {
  static diToken = Symbol('profiles-service');

  private urlPrefix = '/profiles/';

  async getList(params: ListRequestParams) {
    try {
      const { data } = await $http.get<LegacyListRequestResponse<ProfileDTO>>(this.urlPrefix, {
        params: {
          ...normalizeLegacyPagination(params.page),
          ...params.sorting,
        },
      });

      return {
        list: data.items.map((dto) => new Profile(dto)),
        pagination: denormalizeLegacyPagination(data.pagination),
      };
    } catch (err) {
      throw err;
    }
  }

  async searchUsers(params: SearchUsersRequestParams) {
    try {
      const { data } = await $http.get<LegacyListRequestResponse<ProfileDTO>>(
        `${this.urlPrefix}search/`,
        {
          params: {
            ...normalizeLegacyPagination(params.page),
            search: params.search,
          },
        }
      );

      return {
        list: data.items.map((dto) => new Profile(dto)),
        pagination: denormalizeLegacyPagination(data.pagination),
      };
    } catch (err) {
      throw err;
    }
  }

  async exportProfiles(resource: ExportResourceType, search: string) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data/', {
      resource,
      search,
      export: 'profiles',
      type: ExportUserRole.admin,
    });

    return data;
  }

  async changeStatus(params: ChangeStatusRequestParams) {
    await $http.post(`${this.urlPrefix}status/`, params);
  }
}
