import isEqual from 'lodash/isEqual';
import { action, computed, observable } from 'mobx';

export type SurveyQuestionOptionId = string;

export interface SurveyQuestionOptionDTO {
  id?: SurveyQuestionOptionId;
  text: string;
  orderNumber: number;
}

export default class SurveyQuestionOption {
  id: string;
  @observable text: string;
  @observable orderNumber: number;

  private initialData: SurveyQuestionOptionDTO;

  constructor(dto: SurveyQuestionOptionDTO) {
    this.initialData = dto;

    this.init(dto);
  }

  hasChanges() {
    const getDataToCompare = ({ text, orderNumber }: SurveyQuestionOptionDTO) => {
      return { text, orderNumber };
    };

    return !isEqual(getDataToCompare(this.initialData), getDataToCompare(this.asJson));
  }

  @computed get asJson(): SurveyQuestionOptionDTO {
    return {
      id: this.id,
      text: this.text,
      orderNumber: this.orderNumber,
    };
  }

  @action setText = (text: string) => {
    this.text = text;
  };

  @action setOrderNumber = (orderNumber: number) => {
    this.orderNumber = orderNumber;
  };

  @action private init(newData: Partial<SurveyQuestionOptionDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
