import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

import container from '@core/di';
import BusinessOwnersService from '@shared/services/business-owners';
import { ListRequestParams, Pagination } from '@shared/types/services';
import BusinessOwner, {
  BusinessOwnerDTO,
  BusinessOwnerImagesDTO,
} from '@shared/models/business-owner';
import { Id } from '@shared/types/common';
import { ExportResourceType } from '@shared/constants/export';

@injectable()
export default class BusinessOwnersStore {
  static diToken = Symbol('business-users-store');

  private service = container.get<BusinessOwnersService>(BusinessOwnersService.diToken);
  @observable private _businessOwner;
  @observable private _list: Array<BusinessOwnerDTO> = [];
  @observable private _pagination: Pagination;
  @observable loading = {
    list: false,
    businessOwner: false,
  };
  @observable error = {
    businessOwnerGet: false,
  };

  @computed get businessOwner(): BusinessOwner {
    return this._businessOwner;
  }

  @computed get list() {
    return this._list;
  }

  @computed get pagination() {
    return this._pagination;
  }

  @action getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.getList(params);

      this._list = list;
      this._pagination = pagination;
    } finally {
      this.loading.list = false;
    }
  };

  initialize = async (id: Id) => {
    const businessOwner = await this.service.get(id);
    this.setBusinessOwner(businessOwner);
  };

  reset = () => {
    this._businessOwner = undefined;
  };

  @action private setBusinessOwner = (businessOwner: BusinessOwner) => {
    this._businessOwner = businessOwner;
  };

  create = (data: BusinessOwnerDTO) => {
    return this.service.create(data);
  };

  edit = (id: Id, data: BusinessOwnerDTO) => {
    return this.service.edit(id, data);
  };

  update = (id: Id, data: BusinessOwnerImagesDTO) => {
    return this.service.update(id, data);
  };

  get = async (id: Id) => {
    this.error.businessOwnerGet = false;
    this.loading.businessOwner = true;

    try {
      const businessOwner = await this.service.get(id);

      return businessOwner;
    } catch {
      this.error.businessOwnerGet = true;
    } finally {
      this.loading.businessOwner = false;
    }
  };

  exportBushinessOwners = (resource: ExportResourceType) => {
    return this.service.exportBushinessOwners(resource);
  };

  getLogoUploadLink = () => {
    return this.service.getLogoUploadLink();
  };

  uploadLogo = (link: string, logo: ArrayBuffer, extension: File['type']) => {
    return this.service.uploadLogo(link, logo, extension);
  };
}
