import { injectable } from 'inversify';
import { computed, observable, action } from 'mobx';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { SubheaderConfig } from '@modules/Private/components/Header/components/Subheader';

type Device = 'mobile';

export const BREAKPOINT: { [K in Device]: Breakpoint } = {
  mobile: 'xs',
};

const DEFAULT_SUBHEADER_CONFIG: SubheaderConfig | undefined = undefined;

@injectable()
export default class LayoutStore {
  static diToken = Symbol('layout-store');

  @observable private _subheaderConfig = DEFAULT_SUBHEADER_CONFIG;
  @observable private _isCurrentModuleForbidden = false;

  @computed get subheaderConfig() {
    return this._subheaderConfig;
  }

  @computed get isCurrentModuleForbidden() {
    return this._isCurrentModuleForbidden;
  }

  @action setCurrentModuleForbiddenState = (state: boolean) => {
    this._isCurrentModuleForbidden = state;
  };

  @action setDefaultSubheaderConfig = () => {
    this._subheaderConfig = DEFAULT_SUBHEADER_CONFIG;
  };

  @action setSubheaderConfig = (metadata: SubheaderConfig) => {
    this._subheaderConfig = metadata;
  };
}
