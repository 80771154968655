interface Coordinates {
  lat: number;
  lng: number;
}

export interface What3WordsDTO {
  country: string;
  square: {
    southwest: Coordinates;
    northeast: Coordinates;
  };
  nearestPlace: string;
  coordinates: Coordinates;
  words: string;
  language: string;
  map: string;
}

export class What3WordsModel {
  private country: string;
  private square: {
    southwest: Coordinates;
    northeast: Coordinates;
  };
  private nearestPlace: string;
  private coordinates: Coordinates;
  private words: string;
  private language: string;
  private map: string;

  constructor(dto: Partial<What3WordsDTO>) {
    this.update(dto);
  }

  get asJson(): What3WordsDTO {
    return {
      country: this.country,
      square: this.square,
      nearestPlace: this.nearestPlace,
      coordinates: this.coordinates,
      words: this.words,
      language: this.language,
      map: this.map,
    };
  }

  update(newData: Partial<What3WordsDTO>) {
    const data = { ...this.asJson, ...newData };

    Object.assign(this, data);

    return this.asJson;
  }
}
