import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { injectable } from 'inversify';
import { when } from 'mobx';

import container from '@core/di';
import LayoutStore from '@shared/stores/layout';
import Config from '@core/config';
import { showNotification, NotificationType } from '@shared/components/Notification';
import { normalizeDefaultHTTPClientQueryString } from '@shared/utils/services';
import { TokenRefreshStatus } from '@shared/stores/auth/auth.constants';
import { browser } from '@shared/utils/browser';

export enum HttpClientType {
  v1 = 1,
  backend = 2,
  default = 3,
}

interface InitializationConfig {
  getTokenRefreshStatus: () => TokenRefreshStatus;
  getUserLoginStatus: () => boolean;
  getAccessToken: () => string | undefined;
  refreshToken: () => Promise<any>;
}

const httpInstance = {
  [HttpClientType.v1]: Axios.create(),
  [HttpClientType.backend]: Axios.create(),
  [HttpClientType.default]: Axios.create(),
};

export const getHTTPClient = (type: HttpClientType = HttpClientType.default) => {
  return httpInstance[type];
};

@injectable()
export default class HTTPClient {
  static diToken = Symbol('http-client');
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private getAccessToken: InitializationConfig['getAccessToken'];
  private refreshToken: InitializationConfig['refreshToken'];
  private getUserLoginStatus: InitializationConfig['getUserLoginStatus'];
  private getTokenRefreshStatus: InitializationConfig['getTokenRefreshStatus'];

  createInstance = (options?: { cloningInstance?: AxiosInstance }) => {
    if (options?.cloningInstance) {
      const clonedInstance = Axios.create(options.cloningInstance.defaults);

      clonedInstance.interceptors.response.use(
        this.responseSuccessInterceptor,
        this.responseErrorInterceptor
      );

      clonedInstance.interceptors.request.use(
        this.requestSuccessInterceptor,
        this.requestErrorInterceptor
      );

      return clonedInstance;
    }

    const instance = Axios.create();

    return instance;
  };

  retryRequest = (instance: AxiosInstance, config: IAxiosRetryConfig) => {
    const defaultConfig: IAxiosRetryConfig = {
      retryCondition: (err) => {
        return err.response?.status !== 401;
      },
    };

    return axiosRetry(instance, { ...defaultConfig, ...config });
  };

  private getHTTPConfig = (type: HttpClientType) => {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get();
    const httpConfig: {
      [key in HttpClientType]: {
        prefix: string;
        paramsSerializer?: (params: { [key: string]: any }) => string;
      };
    } = {
      [HttpClientType.v1]: {
        prefix: '/api/v1',
      },
      [HttpClientType.backend]: {
        prefix: '/backend/v1',
      },
      [HttpClientType.default]: {
        prefix: apiURL.prefix,
        paramsSerializer: normalizeDefaultHTTPClientQueryString,
      },
    };

    const { prefix, paramsSerializer } = httpConfig[type];

    return {
      paramsSerializer,
      instance: httpInstance[type],
      baseURL: `${apiURL.origin}${prefix}`,
    };
  };

  initialize(config: InitializationConfig) {
    this.httpClientTypes.forEach((type) => {
      const { instance, baseURL, paramsSerializer } = this.getHTTPConfig(type);
      const { getUserLoginStatus, refreshToken, getAccessToken, getTokenRefreshStatus } = config;

      this.refreshToken = refreshToken;
      this.getUserLoginStatus = getUserLoginStatus;
      this.getAccessToken = getAccessToken;
      this.getTokenRefreshStatus = getTokenRefreshStatus;

      this.setDefaults(instance, { baseURL, paramsSerializer });
      this.setRequestInterceptors(instance);
      this.setResponseInterceptors(instance);
    });
  }

  private get authHeader() {
    const accessToken = this.getAccessToken();

    return `Bearer ${accessToken}`;
  }

  private get httpClientTypes(): Array<HttpClientType> {
    return [HttpClientType.v1, HttpClientType.backend, HttpClientType.default];
  }

  private setDefaults(instance: AxiosInstance, defaults: AxiosRequestConfig) {
    instance.defaults.baseURL = defaults.baseURL;
    instance.defaults.paramsSerializer = defaults.paramsSerializer;

    instance.defaults.headers = {
      'Content-Type': 'application/json',
    };

    if (browser?.name == 'ie') {
      Axios.defaults.headers.Pragma = 'no-cache';
    }
  }

  private requestSuccessInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const accessToken = this.getAccessToken();

    if (!accessToken || this.getTokenRefreshStatus() === TokenRefreshStatus.refreshing) {
      return config;
    }

    config.headers.Authorization = this.authHeader;

    return config;
  };

  private requestErrorInterceptor = (error: Error): Error => {
    throw error;
  };

  private setRequestInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(this.requestSuccessInterceptor, this.requestErrorInterceptor);
  }

  private responseErrorInterceptor = async (error) => {
    const response = error ? error.response : undefined;

    if (!response) {
      return;
    }

    if (response.status === 403) {
      this.processForbidden();
    }

    if (response.status === 401) {
      await this.refreshToken();
      await when(() => this.getTokenRefreshStatus() === TokenRefreshStatus.refreshed);

      response.config.headers.Authorization = this.authHeader;

      return Axios(response.config);
    }

    if (response.status >= 500) {
      showNotification('Failed to execute operation', NotificationType.error);
    }

    throw error;
  };

  private responseSuccessInterceptor = (response) => {
    return {
      ...response,
      data: response.data,
    };
  };

  private setResponseInterceptors(instance: AxiosInstance) {
    instance.interceptors.response.use(
      this.responseSuccessInterceptor,
      this.responseErrorInterceptor
    );
  }

  private processForbidden() {
    const isUserLoggedIn = this.getUserLoginStatus();

    if (isUserLoggedIn) {
      this.layoutStore.setCurrentModuleForbiddenState(true);
    }
  }
}
