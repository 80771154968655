import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@shared/stores/layout';

export default function styles({ spacing, palette: { colors }, breakpoints }: Theme) {
  const commonLayoutComponentsStyles = {
    desktop: {
      paddingRight: spacing(6),
      paddingLeft: spacing(6),
    },
    mobile: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
  };

  return createStyles({
    root: {},
    moduleLoading: {
      padding: '150px 0',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    forbiddenMessage: {
      position: 'fixed',
      zIndex: 999999999,
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      background: 'white',
    },
    navigation: {
      ...commonLayoutComponentsStyles.desktop,
    },
    subheader: {
      ...commonLayoutComponentsStyles.desktop,
    },
    module: {
      width: '100%',
      paddingTop: spacing(6),
      paddingBottom: spacing(6),
      backgroundColor: colors.greyLight8,
      ...commonLayoutComponentsStyles.desktop,
    },
    [breakpoints.down(BREAKPOINT.mobile)]: {
      navigation: {
        ...commonLayoutComponentsStyles.mobile,
      },
      subheader: {
        ...commonLayoutComponentsStyles.mobile,
      },
      module: {
        paddingTop: spacing(3),
        paddingBottom: spacing(3),
        ...commonLayoutComponentsStyles.mobile,
      },
    },
  });
}
