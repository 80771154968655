import { SystemUserRole } from './system-user';

export interface CurrentUserDTO {
  id: string;
  name: string;
  email: string;
  roles: SystemUserRole[];
}

export default class CurrentUser {
  id: string;
  name: string;
  email: string;
  roles: SystemUserRole[];

  constructor(dto: Partial<CurrentUserDTO>) {
    this.update(dto);
  }

  get asJson(): CurrentUserDTO {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      roles: this.roles,
    };
  }

  update(newData: Partial<CurrentUserDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
