import Axios from 'axios';
import { injectable } from 'inversify';

import Config from '@core/config';
import container from '@core/di';
import HTTPClient, { HttpClientType, getHTTPClient } from '@core/http-client';
import { SurveyExportType } from '@modules/Private/submodules/Projects/pages/ProjectDetails/pages/SurveyDetails';
import { SurveyStatisticsType } from '@modules/Private/submodules/Projects/pages/ProjectDetails/pages/SurveyDetails/pages/Survey/components/SurveyStatistics/types/survey-statistics-type';
import { ExportResourceType, ExportUserRole } from '@shared/constants/export';
import { AppFileType } from '@shared/models/app-file';
import Project, { ProjectDTO, ProjectStatus } from '@shared/models/project';
import Interview, { InterviewDTO } from '@shared/models/project/interview';
import Survey, { SurveyDTO, SurveyStatus } from '@shared/models/project/survey';
import { SurveyQuestionDTO } from '@shared/models/project/survey/question';
import { SurveyReadDTO, SurveyReadModel } from '@shared/models/project/survey/read-model';
import { SurveyStatisticsDTO } from '@shared/models/project/survey/survey-statistics';
import Team, { TeamDTO } from '@shared/models/team';
import { UploadService } from '@shared/services';
import { Id } from '@shared/types/common';
import {
  ExportSuccessResponse,
  ListRequestParams,
  ListRequestResponse,
} from '@shared/types/services';
import { MultipartUploadFilePart } from './upload';

const $http = getHTTPClient();
const $httpV1 = getHTTPClient(HttpClientType.v1);

export interface SurveyCreateEditData {
  projectId?: ProjectDTO['id'] | null;
  title: string;
  description: string;
  interviewsAmount: number;
  useLocation: boolean;
  questions: Array<SurveyQuestionDTO>;
  fileIds: Array<string>;
  status: SurveyStatus;
}

export interface ShareSurveyData {
  emailsList?: Array<string>;
  emailFile?: File;
}

@injectable()
export default class ProjectsService {
  static diToken = Symbol('projects-service');
  private uploadService = container.get<UploadService>(UploadService.diToken);
  private httpClient = container.get<HTTPClient>(HTTPClient.diToken);
  private config = container.get<Config>(Config.diToken);

  private getSurveysURL = (path: string | number = '') => {
    return path ? `/survey/${path}` : '/survey';
  };

  private getSurveyStatisticsURL = (path: string, type: SurveyStatisticsType) => {
    return `/survey/${path}/stat?type=${type}`;
  };

  private getProjectsURL = (path: string | number = '') => {
    return path ? `/project/${path}` : '/project';
  };

  private getInterviewURL = (path: string | number = '') => {
    return path ? `/interview/${path}` : '/interview';
  };

  private getUserDeleteURL = () => '/user/delete/';

  async getList(params: ListRequestParams) {
    const requestParams: ListRequestParams = {
      ...params,
      include: 'teams',
    };

    const { data } = await $http.get<Array<ProjectDTO> | ListRequestResponse<ProjectDTO>>(
      this.getProjectsURL(),
      {
        params: {
          ...requestParams,
        },
      }
    );

    if (data instanceof Array) {
      return {
        list: data.map((dto) => new Project(dto)),
      };
    }

    return {
      list: data.data.map((dto) => new Project(dto)),
      pagination: data.meta.pagination,
    };
  }

  async getProject(projectId: Id) {
    const { data } = await $http.get<ProjectDTO>(this.getProjectsURL(projectId));

    return new Project(data);
  }

  createProject(project: ProjectDTO) {
    return $http.post(this.getProjectsURL(), project);
  }

  editProject(projectId: Id, data: ProjectDTO) {
    return $http.put(this.getProjectsURL(projectId), data);
  }

  pauseProject(projectId: Id, status: ProjectStatus) {
    return $http.patch(this.getProjectsURL(projectId), { status });
  }

  deleteProject(projectId: Id) {
    return $http.post(this.getProjectsURL('delete'), { ids: [projectId] });
  }

  addTeams(projectId: Id, teamIds: Array<Id>) {
    return $http.patch(this.getProjectsURL(projectId), { teamIds });
  }

  async getSurvey(surveyId: Id) {
    const { data } = await $http.get<SurveyReadDTO>(this.getSurveysURL(surveyId));

    return new SurveyReadModel(data);
  }

  async getSurveyStatistics(surveyId: string, type: SurveyStatisticsType) {
    const {
      data: { data },
    } = await $http.get<ListRequestResponse<SurveyStatisticsDTO>>(
      this.getSurveyStatisticsURL(surveyId, type)
    );

    return data;
  }

  async getSurveyMetadata(surveyId: Id) {
    const { data } = await $http.get<SurveyReadDTO>(`${this.getSurveysURL(surveyId)}/questions`);
    const normalizedSurveyReadDTO: SurveyReadDTO = {
      ...data,
      questions: data?.questions?.sort((a, b) => a.orderNumber - b.orderNumber),
    };

    return new SurveyReadModel(normalizedSurveyReadDTO);
  }

  async getPublicSurvey(token: string) {
    const { data } = await $http.get<SurveyReadDTO>(this.getSurveysURL(`shared/${token}`));
    const normalizedSurveyDTO: SurveyReadDTO = {
      ...data,
      questions: data.questions.sort((a, b) => a.orderNumber - b.orderNumber),
    };

    return new SurveyReadModel(normalizedSurveyDTO);
  }

  async getSurveys(projectId: Id, params: ListRequestParams) {
    const {
      data: { data, meta },
    } = await $http.get<ListRequestResponse<SurveyDTO>>(
      this.getProjectsURL(`${projectId}/surveys`),
      {
        params,
      }
    );

    return {
      list: data.map((dto) => new Survey(dto)),
      pagination: meta.pagination,
    };
  }

  async getSurveyInterviews(surveyId: Id, params: ListRequestParams) {
    const {
      data: { data, meta },
    } = await $http.get<ListRequestResponse<InterviewDTO>>(
      this.getSurveysURL(`${surveyId}/interviews`),
      {
        params,
      }
    );

    return {
      list: data.map((dto) => new Interview(dto)),
      pagination: meta.pagination,
    };
  }

  async getInterview(interviewId: Id) {
    const params: Partial<ListRequestParams> = {
      include: 'survey',
    };

    const {
      data: { survey, ...otherData },
    } = await $http.get<InterviewDTO>(`/interview/${interviewId}`, { params });
    const normalizedData: InterviewDTO = {
      ...otherData,
      survey: {
        ...(survey as SurveyReadDTO),
        questions: (survey as SurveyReadModel).questions
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((question): SurveyQuestionDTO => {
            const answer = otherData.answers?.find((x) => {
              return x.questionId === question.id;
            });

            return {
              ...question,
              options: question.options?.filter(({ id }) => {
                return answer?.options?.some((option) => option.id === id);
              }),
            };
          }),
      },
    };

    return new Interview(normalizedData);
  }

  async getTeams(projectId: Id, params: ListRequestParams) {
    const { data } = await $http.get<Array<TeamDTO> | ListRequestResponse<TeamDTO>>(
      this.getProjectsURL(`${projectId}/teams`),
      {
        params,
      }
    );

    if (data instanceof Array) {
      return {
        list: data.map((dto) => new Team(dto)),
      };
    }

    return {
      list: data.data.map((dto) => new Team(dto)),
      pagination: data.meta.pagination,
    };
  }

  private getSurveyHttpInstance = () => {
    const instance = this.httpClient.createInstance({ cloningInstance: $http });
    const surveyConfig = this.config.get().survey;

    this.httpClient.retryRequest(instance, {
      retries: surveyConfig.submitAttemptsAmount,
    });

    return instance;
  };

  createSurvey(data: SurveyCreateEditData) {
    const instance = this.getSurveyHttpInstance();

    return instance.post(this.getSurveysURL(), data);
  }

  editSurvey(id: Id, data: SurveyCreateEditData) {
    const instance = this.getSurveyHttpInstance();

    return instance.patch(this.getSurveysURL(id), data);
  }

  saveSurveyDraft(data: SurveyCreateEditData) {
    return $http.post(this.getSurveysURL('draft'), data);
  }

  fillInterview(data: SurveyCreateEditData, token: string) {
    const path = this.getInterviewURL(`guest/${token}`);
    const instance = this.getSurveyHttpInstance();

    return instance.post(path, data);
  }

  generateSinglePartPresignedUrl(fileType: AppFileType) {
    return this.uploadService.generateSinglePartPresignedUrl(`/file/upload-link/guest/${fileType}`);
  }

  generateMultiPartPresignedUrls(
    data: {
      file: File;
      fileType: AppFileType;
      contentType: string;
    },
    config: { chunkSize: number }
  ) {
    return this.uploadService.generateMultiPartPresignedUrls(
      '/file/upload-multipart-init/guest',
      { file: data.file },
      config,
      {
        type: data.fileType,
        contentType: data.contentType,
      }
    );
  }

  completeMultiPartUpload(data: {
    uploadId: string;
    parts: Array<MultipartUploadFilePart>;
    objectName: string;
  }) {
    return this.uploadService.completeMultiPartUpload(
      '/file/upload-multipart-complete/guest',
      data
    );
  }

  async getLogoUploadLink() {
    const { data } = await $http.get<{ id: string; link: string }>(`/file/upload-link/image`);

    return data;
  }

  async uploadLogo(link: string, logo: ArrayBuffer, extension: File['type']) {
    const instance = Axios.create();

    return instance.put(link, logo, {
      headers: {
        'Content-Type': extension,
        'x-amz-content-sha256': 'UNSIGNED-PAYLOAD',
      },
    });
  }

  changeSurveyStatus(surveyId: Id, status: SurveyStatus) {
    return $http.patch(this.getSurveysURL(`${surveyId}`), { status });
  }

  deleteTeam(projectId: Id, teamId: Id) {
    return $http.delete(this.getProjectsURL(`${projectId}/team/${teamId}`));
  }

  deleteSurvey(surveyId: Id) {
    return $http.delete(this.getSurveysURL(surveyId));
  }

  deleteUser() {
    return $httpV1.delete(this.getUserDeleteURL());
  }

  async exportSurvey(resource: ExportResourceType, exportType: SurveyExportType, surveyId: Id) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data', {
      resource,
      export: exportType,
      type: ExportUserRole.businessOwner,
      resourceId: surveyId,
    });

    return data;
  }

  async exportProjects(resource: ExportResourceType) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data', {
      resource,
      export: 'projects',
      type: ExportUserRole.businessOwner,
    });

    return data;
  }

  shareSurvey(surveyId: Id, data: ShareSurveyData) {
    const formData = new FormData();

    if (data.emailFile) {
      formData.append('emailsFile', data.emailFile);
    }

    const dataToSend = data.emailsList?.length ? data : formData;

    return $http.post(this.getSurveysURL(`${surveyId}/share`), dataToSend);
  }

  duplicateSurvey(projectId: Id, surveyId: Id) {
    return $http.post(this.getSurveysURL(`${surveyId}/duplicate`), {
      projectId,
    });
  }
}
