interface EntityDTO {
  id: number;
  name: string;
  position: number;
}

export interface ProfileDTO {
  id: string;
  email: string;
  name: string;
  status: string;
  profile: {
    gender: EntityDTO;
    race: EntityDTO;
    ethnicity: EntityDTO;
    education: EntityDTO;
    birthYear: string;
    religion: EntityDTO;
    abilityLevel: EntityDTO;
    incomeRange: EntityDTO;
    zipCode: string;
    industry: EntityDTO;
    country: EntityDTO;
    state: EntityDTO;
    cityName: string;
  };
}

export default class Profile {
  id: string;
  email: string;
  name: string;
  status: string;
  profile: {
    gender: EntityDTO;
    race: EntityDTO;
    ethnicity: EntityDTO;
    education: EntityDTO;
    birthYear: string;
    religion: EntityDTO;
    abilityLevel: EntityDTO;
    incomeRange: EntityDTO;
    zipCode: string;
    industry: EntityDTO;
    country: EntityDTO;
    state: EntityDTO;
    cityName: string;
  };

  constructor(dto: ProfileDTO) {
    this.update(dto);
  }

  get asJson(): ProfileDTO {
    return {
      id: this.id,
      email: this.email,
      name: this.name,
      status: this.status,
      profile: this.profile,
    };
  }

  update(newData: Partial<ProfileDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
