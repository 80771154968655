import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 14,
    },
  });
}
