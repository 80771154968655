import { createStyles, Theme } from '@material-ui/core/styles';

import { NotificationType } from './Notification';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    icon: {
      fontSize: 16,
      marginRight: spacing(2),
    },
    closeButton: {
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    closeIcon: {
      fontSize: 20,
    },
    warningIcon: {
      color: colors.yellow,
    },
    errorIcon: {
      color: colors.white,
    },
    snackbarContentWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: spacing(3),
      },
    },
    [NotificationType.error]: {
      backgroundColor: colors.error,
      color: colors.white,

      '& $closeIcon': {
        fill: colors.white,
      },
    },
    [NotificationType.info]: {},
  });
}
