import Axios from 'axios';
import { injectable } from 'inversify';
import { toJS } from 'mobx';

export interface AuthConfig {
  region: string;
  userPoolId: string;
  userPoolAppClientId: string;
}

export interface SurveyFileConfig {
  maxFiles: number;
  maxSize: number;
  formats: Array<string>;
}

export interface SurveyConfig {
  mediaDescription: {
    searchRadiusMetersForNearLocations: number;
    multiPartUpload: MultiPartUploadConfig;
    audio: SurveyFileConfig;
    video: SurveyFileConfig;
    image: SurveyFileConfig;
    pdf: SurveyFileConfig;
  };
  answer: {
    multiPartUpload: MultiPartUploadConfig;
    audio: SurveyFileConfig;
    video: SurveyFileConfig;
    image: SurveyFileConfig;
    pdf: SurveyFileConfig;
  };
  submitAttemptsAmount: number;
}

export interface ConfigType {
  apiURL: {
    origin: string;
    prefix: string;
  };
  autocomplete: {
    itemsCount: number;
  };
  survey: SurveyConfig;
  auth: AuthConfig;
  mapAPIKey: string;
  version: string;
  RECAPTCHA_SITE_KEY: string;
}

export interface MultiPartUploadConfig {
  minUploadSize: number;
  chunkSize: number;
  reUploadAttemptsAmount: number;
}

@injectable()
export default class Config {
  static diToken = Symbol('config');

  private config: ConfigType;

  initialize() {
    return this.setBaseConfig();
  }

  get() {
    return this.config;
  }

  async updateConfig() {
    type ConfigResponse = { frontend: { auth: AuthConfig; mapAPIKey: string } };

    const httpInstance = Axios.create();
    const { apiURL } = this.config;

    httpInstance.defaults.baseURL = apiURL.origin || window.location.origin;

    const { data } = await httpInstance.get<ConfigResponse>(`${apiURL.prefix}/_apiconf`);

    this.config = {
      ...toJS(this.config),
      ...data.frontend,
    };
  }

  private async setBaseConfig() {
    const httpInstance = Axios.create();

    httpInstance.defaults.baseURL = window.location.origin;

    const { data } = await httpInstance.get('./public/config.json');

    this.config = data;
  }
}
