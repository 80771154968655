import { injectable } from 'inversify';
import Amplify, { Auth } from 'aws-amplify';

import SystemUser from '@shared/models/system-user';
import { ConfigType } from '@core/config';
import { getHTTPClient } from '@core/http-client';
import CurrentUser from '@shared/models/current-user';

const $http = getHTTPClient();
const urlPrefix = '/auth';

export enum LoginResponseType {
  default = 'default',
  newPasswordSet = 'newPasswordSet',
}

@injectable()
export default class AuthService {
  static diToken = Symbol('auth-service');

  async verifyUser(token: string) {
    interface Response {
      'error-codes'?: Array<string>;
      action?: string;
      challenge_ts: string;
      hostname: string;
      score: number;
      success: boolean;
    }

    const { data } = await $http.post<Response>('/siteverify', {
      response: token,
    });

    return data;
  }

  setAuthConfig(config: ConfigType['auth']) {
    const { userPoolAppClientId, ...otherConfigProperties } = config;

    Amplify.configure({
      Auth: {
        ...otherConfigProperties,
        userPoolWebClientId: userPoolAppClientId,
      },
    });
  }

  async getUser() {
    try {
      const {
        attributes: { email, 'custom:role': role, sub },
      } = await Auth.currentUserInfo();

      return new SystemUser({ role, email, id: sub });
    } catch (err) {
      throw err;
    }
  }

  async whoAmI() {
    try {
      const { data } = await $http.get(`/auth/whoami`);

      return new CurrentUser(data);
    } catch (err) {
      throw err;
    }
  }

  async refreshToken() {
    try {
      await Auth.currentSession();

      return this.getAccessRefreshTokens();
    } catch (err) {
      throw err;
    }
  }

  private getSession() {
    return Auth.currentSession();
  }

  private async getAccessRefreshTokens() {
    try {
      const session = await this.getSession();

      return {
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
      };
    } catch (err) {
      throw err;
    }
  }

  async login(data: { username: string; password: string }) {
    try {
      const authUser = await Auth.signIn(data.username, data.password);

      if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          user: authUser,
          type: LoginResponseType.newPasswordSet,
        };
      }

      const systemUser = await this.getUser();
      const { accessToken, refreshToken } = await this.getAccessRefreshTokens();

      return {
        accessToken,
        refreshToken,
        user: systemUser,
        type: LoginResponseType.default,
      };
    } catch (err) {
      throw err;
    }
  }

  activateAccount(data: { email: string; temporaryPassword: string; permanentPassword: string }) {
    const { email, temporaryPassword, permanentPassword } = data;

    return $http.post(`${urlPrefix}/set-permanent-password`, {
      email,
      permanentPassword,
      tempPassword: temporaryPassword,
    });
  }

  resetPassword(username: string) {
    return $http.post(`${urlPrefix}/reset`, {
      email: username,
      platform: 'WEBCONSOLE',
    });
  }

  setNewPassword(username: string, data: { verificationCode: string; password: string }) {
    const { verificationCode, password } = data;

    return Auth.forgotPasswordSubmit(username, verificationCode, password);
  }

  changePassword(data: { oldPassword: string; newPassword: string }) {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, data.oldPassword, data.newPassword);
    });
  }

  resendInvitation = (email: string) => {
    return $http.post(`${urlPrefix}/resend-password`, { email });
  };

  logout() {
    return Auth.signOut();
  }
}
