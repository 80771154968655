import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LocationDescriptorObject, Path } from 'history';

import Button, { ButtonProps } from '@shared/components/Button';
import Ellipsis from '@shared/components/Ellipsis';
import history from '@shared/utils/history';

import styles from './BackButton.styles';

type Classes = WithStyles<typeof styles> & Pick<ButtonProps, 'classes'>;

export interface BackButtonProps extends Omit<ButtonProps, 'classes'>, Classes {
  location?: LocationDescriptorObject | Path;
  text?: React.ReactNode;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { classes, text, children, location, onClick } = props;
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);

      return;
    }

    if (location) {
      history.push(location);

      return;
    }

    history.goBack();
  };
  const {
    root: rootClass,
    labelText: labelTextClass,
    iconWrapper: iconWrapperClass,
    icon,
    ...otherClasses
  } = classes;

  return (
    <Button
      variant="text"
      color="default"
      onClick={handleClick}
      classes={{
        root: rootClass,
        ...otherClasses,
      }}
    >
      <span className={iconWrapperClass}>
        <ArrowBackIcon className={icon} />
      </span>
      {children || <Ellipsis classes={{ root: labelTextClass }} text={text} />}
    </Button>
  );
};

BackButton.defaultProps = {
  text: 'Back',
};

export default withStyles(styles)(BackButton);
