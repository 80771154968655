interface Coordinates {
  lat: number;
  lng: number;
}

export interface FourSquareBeenHere {
  count: number;
  lastCheckinExpiredAt: number;
  marked: boolean;
  unconfirmedCount: number;
}

export interface FourSquareCategory {
  icon: {
    prefix: string;
    suffix: string;
  };
  id: string;
  name: string;
  pluralName: string;
  primary: boolean;
  shortName: string;
}

export interface FourSquareHereNowGroup {}

export interface FourSquareHereNow {
  count: number;
  groups: Array<FourSquareHereNowGroup>;
  summary: string;
}

export interface FourSquareStats {
  checkinsCount: number;
  tipCount: number;
  usersCount: number;
  visitsCount: number;
}

export interface FourSquareLocation extends Coordinates {
  address: string;
  cc: string;
  city: string;
  country: string;
  crossStreet: string;
  distance: number;
  formattedAddress: Array<string>;
  postalCode: string;
  state: string;
}

export interface FourSquareLocationVenueChain {}

export interface FourSquareLocationVenuePage {
  id: string;
}

export interface FourSquareDTO {
  beenHere: FourSquareBeenHere;
  categories: Array<FourSquareCategory>;
  contact: object;
  hasPerk: boolean;
  hereNow: FourSquareHereNow;
  id: string;
  location: FourSquareLocation;
  name: string;
  referralId: string;
  stats: FourSquareStats;
  venueChains: Array<FourSquareLocationVenueChain>;
  venuePage: FourSquareLocationVenuePage;
  verified: boolean;
}

export class FourSquareModel {
  private beenHere: FourSquareBeenHere;
  private categories: Array<FourSquareCategory>;
  private contact: object;
  private hasPerk: boolean;
  private hereNow: FourSquareHereNow;
  private id: string;
  private location: FourSquareLocation;
  private name: string;
  private referralId: string;
  private stats: FourSquareStats;
  private venueChains: Array<FourSquareLocationVenueChain>;
  private venuePage: FourSquareLocationVenuePage;
  private verified: boolean;

  constructor(dto: Partial<FourSquareDTO>) {
    this.update(dto);
  }

  get asJson(): FourSquareDTO {
    return {
      beenHere: this.beenHere,
      categories: this.categories,
      contact: this.contact,
      hasPerk: this.hasPerk,
      hereNow: this.hereNow,
      id: this.id,
      location: this.location,
      name: this.name,
      referralId: this.referralId,
      stats: this.stats,
      venueChains: this.venueChains,
      venuePage: this.venuePage,
      verified: this.verified,
    };
  }

  update(newData: Partial<FourSquareDTO>) {
    const data = { ...this.asJson, ...newData };

    Object.assign(this, data);

    return this.asJson;
  }
}
