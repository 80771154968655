import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors }, opacity }: Theme) {
  return createStyles({
    root: {
      padding: spacing(0, 3, 0, 1),
      color: colors.primary,

      '&:hover': {
        opacity: opacity.hover,
      },
    },
    iconWrapper: {
      display: 'flex',
      marginRight: spacing(2),
    },
    icon: {
      fontSize: 17,
    },
    labelText: {
      fontWeight: 'inherit',
      fontSize: 'inherit',
    },
  });
}
