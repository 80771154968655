import { createStyles, Theme } from '@material-ui/core/styles';
import { BREAKPOINT } from '@shared/stores/layout';

export default function styles({ palette: { colors }, breakpoints }: Theme) {
  return createStyles({
    root: {
      backgroundColor: colors.white,
    },
    heading: {
      fontSize: 22,
      fontWeight: 500,
    },
    closeIcon: {
      padding: 0,
      backgroundColor: colors.white,

      '&:hover': {
        backgroundColor: colors.greyLight,
      },
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      heading: {
        fontSize: 20,
      },
    },
  });
}
