import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      height: '80vh',
    },
    icon: {
      fontSize: 64,
    },
    message: {
      fontSize: 24,
      margin: spacing(4, 0, 0),
      color: colors.grey,
    },
    errorCode: {
      fontSize: 13,
      color: colors.greyLight2,
      marginBottom: spacing(8),
    },
  });
}
