import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import MuiPopover from '@material-ui/core/Popover';
import isEqual from 'lodash/isEqual';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import Button from '@shared/components/Button';
import Ellipsis from '@shared/components/Ellipsis';
import Flex from '@shared/components/Flex';
import Avatar from '@shared/components/Avatar';
import SystemUser, { SystemUserDTO } from '@shared/models/system-user';
import { getTestAttr } from '@shared/utils/common';
import ROUTES from '@shared/constants/routes';
import BusinessOwner from '@shared/models/business-owner';

import styles from './ProfileInfo.styles';

export interface ProfileInfoProps extends WithStyles<typeof styles>, RouteComponentProps {
  user: SystemUser;
  businessOwner?: BusinessOwner;
  logout: () => Promise<any>;
}

class ProfileInfo extends React.Component<ProfileInfoProps> {
  readonly state = {
    anchorEl: null,
    loggingOut: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { user, location } = this.props;
    const currentUserData: Partial<SystemUserDTO> = {
      email: user.email,
    };
    const nextUserData: Partial<SystemUserDTO> = {
      email: nextProps.user.email,
    };

    if (!isEqual(currentUserData, nextUserData)) {
      return true;
    }

    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.location.pathname !== location.pathname) {
      this.setState((state) => ({ ...state, anchorEl: null }));
    }

    return false;
  }

  private handleOpen = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, anchorEl: e.target }));
  };

  private handleClose = () => {
    this.setState((state) => ({ ...state, anchorEl: null }));
  };

  private logout = async () => {
    this.setState((state) => ({ ...state, loggingOut: true }));

    try {
      await this.props.logout();
    } catch {
      this.setState((state) => ({ ...state, loggingOut: false }));
    }
  };

  private get authButtons() {
    const { classes, location } = this.props;

    return (
      <Flex alignItems="center" classes={{ root: classes.authBtnsWrapper }}>
        <Button
          variant="text"
          className={classes.logoutBtn}
          loading={this.state.loggingOut}
          onClick={this.logout}
        >
          Log Out
        </Button>
        <Button
          disabled={location.pathname === ROUTES.private.changePassword}
          variant="text"
          component={Link}
          componentProps={{
            to: ROUTES.private.changePassword,
          }}
        >
          Change Password
        </Button>
      </Flex>
    );
  }

  render() {
    const { classes, user, businessOwner } = this.props;
    const { anchorEl } = this.state;

    const commonAvatarProps = {
      ...getTestAttr('Layout', 'header', 'avatar'),
      src: businessOwner?.logo?.link,
      fullName: user.email,
    };

    return (
      <>
        <Avatar {...commonAvatarProps} className={classes.smallAvatar} onClick={this.handleOpen} />
        <MuiPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ root: classes.root, paper: classes.menuWrapper }}
          onClose={this.handleClose}
        >
          <div className={classes.content}>
            <Flex alignItems="center">
              <Avatar {...commonAvatarProps} className={classes.bigAvatar} />
              <Ellipsis
                maxWidth="calc(100% - 56px)"
                classes={{ root: classes.email }}
                text={user.email}
              />
            </Flex>
            {this.authButtons}
          </div>
          {/* {user.role !== SystemUserRole.admin && (
            <>
              <div className={classes.divider} />
              {this.supportButtons}
            </>
          )} */}
        </MuiPopover>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(ProfileInfo));
