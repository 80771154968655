import { injectable } from 'inversify';
import { computed, observable } from 'mobx';

import container from '@core/di';
import ProfilesService from '@shared/services/profiles';
import { ChangeStatusRequestParams, ListRequestParams, Pagination } from '@shared/types/services';
import Profile from '@shared/models/profile';
import { ExportResourceType } from '@shared/constants/export';

@injectable()
export default class ProfilesStore {
  static diToken = Symbol('profiles-store');

  private service = container.get<ProfilesService>(ProfilesService.diToken);
  @observable private _list: Array<Profile> = [];
  @observable private _pagination: Pagination;
  @observable private _searchValue = '';
  @observable loading = {
    list: false,
    status: false,
  };

  @computed get list() {
    return this._list;
  }

  @computed get pagination() {
    return this._pagination;
  }

  @computed get searchValue() {
    return this._searchValue;
  }

  handleChangeSearchValue(value: string) {
    this._searchValue = value;
  }

  getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.getList(params);

      this._list = list;
      this._pagination = pagination;
    } finally {
      this.loading.list = false;
    }
  };

  searchUsers = async () => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.searchUsers({
        search: this._searchValue,
        page: this._pagination,
      });

      this._list = list;
      this._pagination = pagination;
    } finally {
      this.loading.list = false;
    }
  };

  exportProfiles = (resource: ExportResourceType) => {
    return this.service.exportProfiles(resource, this._searchValue);
  };

  changeStatus = async (params: ChangeStatusRequestParams) => {
    this.loading.status = true;
    try {
      await this.service.changeStatus(params);
    } finally {
      this.loading.status = false;
    }
  };
}
