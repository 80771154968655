import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Tabs from '@shared/components/Tabs';
import ProfileInfo from '@shared/components/ProfileInfo';
import SystemUser, { SystemUserRole } from '@shared/models/system-user';
import BusinessOwner from '@shared/models/business-owner';

import styles from './Navigation.styles';

interface NavigationLink {
  name: string;
  route: string;
}

export interface NavigationProps extends WithStyles<typeof styles> {
  user: SystemUser;
  businessOwner?: BusinessOwner;
  links: Array<NavigationLink>;
  logout: () => Promise<any>;
}

const Navigation: React.FC<NavigationProps> = ({ classes, user, businessOwner, links, logout }) => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.root}
    >
      <Tabs
        links={links}
        classes={{
          root: classes.tabs,
          link: classes.link,
          indicator: classes.indicator,
        }}
      />
      {user.role !== SystemUserRole.mobileUser && (
        <ProfileInfo user={user} businessOwner={businessOwner} logout={logout} />
      )}
    </Grid>
  );
};

export default withStyles(styles)(Navigation);
