import { stringify } from 'qs';

import {
  LegacyPagination,
  LegacyRequestPagination,
  ListRequestParams,
  Page,
  Pagination,
} from '@shared/types/services';

const PAGE_BIAS = 1;

export const normalizeLegacyPagination = (pagination: Page): LegacyRequestPagination => {
  const { offset = 0, limit = 0 } = pagination;

  return {
    page: offset / limit + PAGE_BIAS,
    'per-page': limit,
  };
};

export const denormalizeLegacyPagination = (pagination: LegacyPagination): Pagination => {
  return {
    offset: pagination.current_page / pagination.per_page - PAGE_BIAS,
    limit: pagination.per_page,
    total: pagination.total_items_count,
  };
};

export const normalizeDefaultHTTPClientQueryString = (params: ListRequestParams): string => {
  return stringify(params, {
    encode: false,
    arrayFormat: 'comma',
  });
};
