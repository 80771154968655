import { computed, observable } from 'mobx';

import { SurveyLocationCoordinatesDTO } from './coordinates';

export interface SurveyLocationReadDTO extends SurveyLocationCoordinatesDTO {
  address: string;
  what3Words: string;
}

export class SurveyLocationReadModel {
  @observable private address: string;
  @observable private latitude: string;
  @observable private longitude: string;
  @observable private what3Words: string;

  constructor(dto: Partial<SurveyLocationReadDTO>) {
    this.update(dto);
  }

  @computed get asJson(): SurveyLocationReadDTO {
    return {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude,
      what3Words: this.what3Words,
    };
  }

  update(newData: Partial<SurveyLocationReadDTO>) {
    const data = { ...this.asJson, ...newData };

    Object.assign(this, data);

    return this.asJson;
  }
}
