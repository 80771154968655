const ROUTES = {
  public: {
    login: '/login',
    newPassword: '/new-password',
    forgotPassword: '/forgot-password',
    accountActivate: '/account-activate',
    surveys: '/surveys',
    deleteAccount: '/deleteaccount',
  },
  private: {
    experiences: '/experiences',
    profiles: '/profiles',
    businessOwners: '/business-owners',
    dashboard: '/dashboard',
    projects: '/projects',
    teams: '/teams',
    changePassword: '/change-password',
    userDeletion: '/user-deletion',
  },
  initial: '/',
};

export default ROUTES;
