import * as React from 'react';
import withStyles, { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';

import Navigation, {
  NavigationProps,
} from '@modules/Private/components/Header/components/Navigation';
import Subheader, {
  SubheaderConfig,
} from '@modules/Private/components/Header/components/Subheader';

import styles from './Header.styles';

export interface HeaderProps extends WithStyles<typeof styles> {
  navigationProps: Omit<NavigationProps, 'classes'>;
  shouldRenderSubheader: boolean;
  style?: CSSProperties;
  subheaderProps?: SubheaderConfig;
}

class Header extends React.Component<HeaderProps> {
  render() {
    const { classes, navigationProps, subheaderProps, shouldRenderSubheader, style } = this.props;

    return (
      <div style={style} className={classes.root}>
        <Navigation classes={{ root: classes.navigation }} {...navigationProps} />
        {shouldRenderSubheader && (
          <Subheader classes={{ root: classes.subheader }} {...subheaderProps} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Header);
