import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Message from '@shared/components/Message';
import { ButtonProps } from '@shared/components/Button';
import history from '@shared/utils/history';
import ROUTES from '@shared/constants/routes';

import styles from './ErrorHandler.styles';

export interface ErrorHandlerProps extends WithStyles<typeof styles> {
  heading?: React.ReactNode;
  icon?: React.ReactNode;
  buttonProps?: Partial<ButtonProps>;
  withButton?: boolean;
}

const handleGoToInitialPage = () => {
  history.push(ROUTES.initial);
};

class ErrorHandler extends React.Component<ErrorHandlerProps> {
  static defaultProps = {
    heading: 'Something went wrong',
    buttonProps: {
      text: 'Go to home page',
      onClick: handleGoToInitialPage,
    },
  };

  render() {
    const { classes, heading, icon, buttonProps, withButton } = this.props;

    return (
      <Message
        classes={{
          root: classes.root,
          heading: classes.heading,
        }}
        heading={heading}
        icon={icon}
        buttonProps={
          withButton
            ? {
                size: 'small',
                ...buttonProps,
              }
            : undefined
        }
      />
    );
  }
}

export default withStyles(styles)(ErrorHandler);
