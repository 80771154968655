import 'core-js/es6/symbol';
import 'core-js/es6/promise';
import 'core-js/es6/weak-set';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/ends-with';
import 'core-js/fn/number/is-integer';
import 'core-js/fn/array/from';
import 'core-js/fn/array/find';
import 'core-js/fn/array/fill';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/find-index';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/values';
import 'core-js/fn/object/keys';
import 'core-js/fn/object/assign';
import 'core-js/es6/object';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/set';
import 'core-js/es6/typed';

// tslint:disable:no-bitwise
// tslint:disable:prefer-for-of

// arrayBuffer POLYFILL

((global) => {
  ((factory) => {
    if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
      factory(exports);
    } else {
      factory(global);
    }
  })((exports) => {
    const BlobBuilder = global?.MSBlobBuilder;
    let blobSupported = false;
    const arrayBufferSupported = !!global?.ArrayBuffer;
    const blobBuilderSupported =
      BlobBuilder && BlobBuilder.prototype.append && BlobBuilder.prototype.getBlob;

    try {
      blobSupported = new Blob(['ä']).size === 2;
    } catch {}

    function BlobBuilderConstructor(options) {
      const bb = BlobBuilder && new BlobBuilder();
      options = options || {};

      return options.type ? bb?.getBlob(options.type) : bb?.getBlob();
    }

    if (global?.Blob) {
      BlobBuilderConstructor.prototype = Blob.prototype;
    }

    function FakeBlobBuilder() {
      function isDataView(obj) {
        return obj && Object.prototype.isPrototypeOf.call(DataView, obj);
      }
      function bufferClone(buf) {
        const view = new Array(buf.byteLength);
        const array = new Uint8Array(buf);
        let i = view.length;

        while (i--) {
          view[i] = array[i];
        }

        return view;
      }

      function Blob(this: any, chunks, opts) {
        chunks = chunks || [];
        opts = opts == null ? {} : opts;

        for (let i = 0, len = chunks.length; i < len; i++) {
          const chunk = chunks[i];

          if (arrayBufferSupported && Object.prototype.isPrototypeOf.call(ArrayBuffer, chunk)) {
            chunks[i] = bufferClone(chunk);
          } else if (arrayBufferSupported && isDataView(chunk)) {
            chunks[i] = bufferClone(chunk.buffer);
          }
        }

        this._buffer = [].concat.apply([], chunks);

        this.size = this._buffer.length;
        this.type = opts.type || '';

        if (/[^\u0020-\u007E]/.test(this.type)) {
          this.type = '';
        } else {
          this.type = this.type.toLowerCase();
        }
      }

      Blob.prototype.arrayBuffer = function () {
        return Promise.resolve(this._buffer.buffer || this._buffer);
      };

      exports.Blob = Blob;
    }

    if (blobSupported) {
      exports.Blob = global?.Blob;
    } else if (blobBuilderSupported) {
      exports.Blob = BlobBuilderConstructor;
    } else {
      FakeBlobBuilder();
    }

    const blob = exports.Blob.prototype;

    function promisify(obj) {
      return new Promise((resolve, reject) => {
        obj.onload = obj.onerror = (evt) => {
          obj.onload = obj.onerror = null;

          evt.type === 'load'
            ? resolve(obj.result || obj)
            : reject(new Error('Failed to read the blob/file'));
        };
      });
    }

    if (!blob.arrayBuffer) {
      blob.arrayBuffer = function arrayBuffer() {
        const fr = new FileReader();
        fr.readAsArrayBuffer(this);

        return promisify(fr);
      };
    }
  });
})(
  (typeof self !== 'undefined' && self) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    this
);
