import { SystemUserRole } from '@shared/models/system-user';

const ACCESS = {
  home: [SystemUserRole.admin],
  experiences: [SystemUserRole.admin],
  profiles: [SystemUserRole.admin],
  businessOwners: [SystemUserRole.admin],
  dashboard: [SystemUserRole.businessOwner],
  projects: [SystemUserRole.businessOwner],
  teams: [SystemUserRole.businessOwner],
  changePassword: [SystemUserRole.businessOwner, SystemUserRole.admin],
  userDeletion: [SystemUserRole.mobileUser],
};

export default ACCESS;
