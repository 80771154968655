import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

import container from '@core/di';
import AuthService, { LoginResponseType } from '@shared/services/auth';
import SystemUser, { SystemUserRole } from '@shared/models/system-user';
import Config from '@core/config';
import history from '@shared/utils/history';
import ROUTES from '@shared/constants/routes';
import { NotificationType, showNotification } from '@shared/components/Notification';
import { getEnumValues } from '@shared/utils/enums';
import { setCookie, removeCookie, getCookie, getStorageKey } from '@shared/utils/storage';
import { TokenRefreshStatus } from './auth.constants';
import CurrentUser from '@shared/models/current-user';
import BusinessOwnersStore from '@shared/stores/business-owners';

const STORAGES_KEYS = {
  accessToken: getStorageKey('accessToken'),
  refreshToken: getStorageKey('refreshToken'),
  accountActivateEmail: getStorageKey('accountActivateEmail'),
  accountActivateTemporaryPassword: getStorageKey('accountActivateTemporaryPassword'),
};

@injectable()
export default class AuthStore {
  static diToken = Symbol('auth-store');

  private service = container.get<AuthService>(AuthService.diToken);
  private businessOwnersStore = container.get<BusinessOwnersStore>(BusinessOwnersStore.diToken);
  private config = container.get<Config>(Config.diToken);
  private setNewPasswordMetadata: { username: string } | undefined;
  @observable private _user;
  @observable private _currentUser;
  @observable private _tokenRefreshStatus = TokenRefreshStatus.initial;

  @computed get user(): SystemUser {
    return this._user;
  }

  @computed get currentUser(): CurrentUser {
    return this._currentUser;
  }

  @computed get loggedIn() {
    return Boolean(this.user);
  }

  @computed get tokenRefreshStatus() {
    return this._tokenRefreshStatus;
  }

  @computed get hasEnoughDataForActivateAccount() {
    const { accountActivateEmail, accountActivateTemporaryPassword } = this.accountActivateData;

    return Boolean(accountActivateEmail && accountActivateTemporaryPassword);
  }

  @computed get hasEnoughDataForNewPassword() {
    return Boolean(this.setNewPasswordMetadata);
  }

  get tokens() {
    return {
      access: getCookie(STORAGES_KEYS.accessToken),
      refresh: getCookie(STORAGES_KEYS.refreshToken),
    };
  }

  initialize = async () => {
    this.service.setAuthConfig(this.config.get().auth);

    if (!this.tokens.refresh && !this.tokens.access) {
      this.logout();
      return;
    }

    try {
      const user = await this.service.getUser();
      this.setUser(user);
      const currentUser = await this.service.whoAmI();
      this.setCurrentUser(currentUser);
    } catch {
      this.logout();
    }
  };

  @action private setUser = (user: SystemUser) => {
    this._user = user;
  };

  @action private setCurrentUser = (currentUser: CurrentUser) => {
    this._currentUser = currentUser;
  };

  private get accountActivateData() {
    return {
      accountActivateEmail: getCookie(STORAGES_KEYS.accountActivateEmail),
      accountActivateTemporaryPassword: getCookie(STORAGES_KEYS.accountActivateTemporaryPassword),
    };
  }

  login = async (data: { username: string; password: string }) => {
    try {
      const { type, user, accessToken, refreshToken } = await this.service.login(data);

      if (type === LoginResponseType.default && accessToken && refreshToken) {
        const appRoles = getEnumValues(SystemUserRole);

        if (appRoles.includes(user.role)) {
          this.setTokens({ accessToken, refreshToken });
          this.setUser(user);

          const currentUser = await this.service.whoAmI();
          this.setCurrentUser(currentUser);

          if (currentUser && currentUser.roles.includes(SystemUserRole.businessOwner)) {
            this.businessOwnersStore.initialize(currentUser.id);
          }
        } else {
          showNotification('Incorrect username or password.', NotificationType.error);

          return;
        }
      }

      if (type === LoginResponseType.newPasswordSet) {
        this.setActivateAccountData({
          email: data.username,
          temporaryPassword: data.password,
        });
        history.push(ROUTES.public.accountActivate);
      }
    } catch (err) {
      throw err;
    }
  };

  activateAccount = async (data: { permanentPassword: string }) => {
    const { permanentPassword } = data;
    const { accountActivateEmail, accountActivateTemporaryPassword } = this.accountActivateData;

    if (this.hasEnoughDataForActivateAccount) {
      try {
        await this.service.activateAccount({
          permanentPassword,
          email: accountActivateEmail as string,
          temporaryPassword: accountActivateTemporaryPassword as string,
        });

        this.deleteActivateAccountData();
      } catch (err) {
        showNotification(err?.response?.data?.message, NotificationType.error);
      }
    }
  };

  @action refreshToken = async () => {
    if (this.tokenRefreshStatus === TokenRefreshStatus.refreshing) {
      return;
    }

    if (!this.tokens.refresh) {
      this.logout();

      return;
    }

    this._tokenRefreshStatus = TokenRefreshStatus.refreshing;

    try {
      const tokens = await this.service.refreshToken();

      this.setTokens(tokens);
    } catch {
      this.logout();
    } finally {
      this._tokenRefreshStatus = TokenRefreshStatus.refreshed;
    }
  };

  resetPassword = (username: string) => {
    this.setNewPasswordMetadata = {
      username,
    };

    return this.service.resetPassword(username);
  };

  setNewPassword = (data: { verificationCode: string; password: string }) => {
    if (this.setNewPasswordMetadata?.username) {
      return this.service.setNewPassword(this.setNewPasswordMetadata?.username, data);
    }
  };

  changePassword = (data: { oldPassword: string; newPassword: string }) => {
    return this.service.changePassword(data);
  };

  logout = async () => {
    try {
      await this.service.logout();
    } catch {
    } finally {
      this.reset();
    }
  };

  private setActivateAccountData = (data: { email: string; temporaryPassword: string }) => {
    setCookie(STORAGES_KEYS.accountActivateEmail, data.email);
    setCookie(STORAGES_KEYS.accountActivateTemporaryPassword, data.temporaryPassword);
  };

  private deleteActivateAccountData = () => {
    removeCookie(STORAGES_KEYS.accountActivateEmail);
    removeCookie(STORAGES_KEYS.accountActivateTemporaryPassword);
  };

  private setTokens(tokens: { accessToken: string; refreshToken: string }) {
    setCookie(STORAGES_KEYS.accessToken, tokens.accessToken);
    setCookie(STORAGES_KEYS.refreshToken, tokens.refreshToken);
  }

  private deleteTokens() {
    removeCookie(STORAGES_KEYS.accessToken);
    removeCookie(STORAGES_KEYS.refreshToken);
  }

  resendInvitation = (email: string) => {
    return this.service.resendInvitation(email);
  };

  private reset() {
    this.deleteTokens();

    this._user = undefined;
    this._currentUser = undefined;
    this.businessOwnersStore.reset();
  }
}
