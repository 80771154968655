import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import Button, { ButtonProps } from '@shared/components/Button';

import styles from './NoMatch.styles';

export interface NoMatchProps extends WithStyles {
  forbidden?: boolean;
  btnProps?: Partial<ButtonProps>;
}

const NoMatch: React.FC<NoMatchProps> = ({ classes, forbidden, btnProps }) => {
  const message = forbidden
    ? 'You don’t have permission to access the requested page'
    : 'The page you were looking for doesn’t exist';
  const errorCode = forbidden ? 403 : 404;

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <h6 className={classes.message}>{message}</h6>
      <span className={classes.errorCode}>Error code: {errorCode}</span>
      <Button text="Go to home page" component={Link} componentProps={{ to: '/' }} {...btnProps} />
    </Grid>
  );
};

export default withStyles(styles)(NoMatch);
