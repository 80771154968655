import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 28,
      marginRight: spacing(2),
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 1.2,
      textTransform: 'uppercase',
    },
    rootHidden: {
      display: 'none',
    },
  });
