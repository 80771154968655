import { TeamDTO } from '@shared/models/team';

export enum ProjectStatus {
  active = 'active',
  inactive = 'inactive',
  notStarted = 'not_started',
  collected = 'collected',
  finished = 'finished',
  paused = 'paused',
  resume = 'resume',
}

export const PROJECT_CREATE_SURVEY_STATUSES = [
  ProjectStatus.inactive,
  ProjectStatus.notStarted,
  ProjectStatus.active,
  ProjectStatus.paused,
  ProjectStatus.collected,
];

export const PROJECT_ASSIGN_TEAM_STATUSES = [
  ProjectStatus.inactive,
  ProjectStatus.active,
  ProjectStatus.paused,
  ProjectStatus.notStarted,
];

export const PROJECT_RESTRICTED_STATUSES_TO_DELETE_SURVEY = [ProjectStatus.finished];

export const PROJECT_FILL_SURVEY_STATUSES = [ProjectStatus.active, ProjectStatus.collected];

export interface ProjectLogo {
  id: string;
  link: string;
}

export interface ProjectDTO {
  description: string;
  endDate: string;
  id: string;
  title: string;
  startDate: string;
  status: ProjectStatus;
  teams: Array<TeamDTO>;
  surveysCount: number;
  archiveVisible?: boolean;
  logoId?: string | null;
  logo?: ProjectLogo;
  surveysAmount?: number;
}

export default class Project {
  description: string;
  endDate: string;
  id: string;
  title: string;
  startDate: string;
  status: ProjectStatus;
  surveysCount: number;
  teams: Array<TeamDTO>;
  archiveVisible?: boolean;
  logoId?: string | null;
  logo?: ProjectLogo;
  surveysAmount?: number;

  constructor(dto: Partial<ProjectDTO>) {
    this.update(dto);
  }

  get canCreateSurvey() {
    return PROJECT_CREATE_SURVEY_STATUSES.includes(this.status);
  }

  get canAssignTeams() {
    return PROJECT_ASSIGN_TEAM_STATUSES.includes(this.status);
  }

  get canDeleteSurvey() {
    return !PROJECT_RESTRICTED_STATUSES_TO_DELETE_SURVEY.includes(this.status);
  }

  get asJson(): ProjectDTO {
    return {
      description: this.description,
      endDate: this.endDate,
      id: this.id,
      title: this.title,
      startDate: this.startDate,
      status: this.status,
      teams: this.teams,
      surveysCount: this.surveysCount,
      archiveVisible: this.archiveVisible,
      logoId: this.logoId,
      logo: this.logo,
      surveysAmount: this.surveysAmount,
    };
  }

  update(newData: Partial<ProjectDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
