import { withStyles, WithStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';

import container from '@core/di';
import { getNavigationLinks } from '@modules/Private/constants/navigation';
import ErrorHandler from '@shared/components/ErrorHandler';
import Lazyload from '@shared/components/LazyLoad';
import NoMatch from '@shared/components/NoMatch';
import ACCESS from '@shared/constants/access';
import ROUTES from '@shared/constants/routes';
import AuthStore from '@shared/stores/auth';
import BusinessOwnersStore from '@shared/stores/business-owners';
import LayoutStore from '@shared/stores/layout';
import { MergeTypes } from '@shared/types/common';
import { getDefaultRoute } from '@shared/utils/common';
import { Footer } from './components/Footer';
import Header from './components/Header';

import styles from './Private.styles';

const Experiences = React.lazy(() => import('./submodules/Experiences'));
const Profiles = React.lazy(() => import('./submodules/Profiles'));
const BusinessOwners = React.lazy(() => import('./submodules/BusinessOwners'));
const Dashboard = React.lazy(() => import('./submodules/Dashboard'));
const Projects = React.lazy(() => import('./submodules/Projects'));
const Teams = React.lazy(() => import('./submodules/Teams'));
const UserDeletion = React.lazy(() => import('./submodules/UserDeletion'));
const ChangePassword = React.lazy(() => import('./submodules/ChangePassword'));

export interface PrivateModuleProps extends WithStyles<typeof styles>, RouteComponentProps {}

const PUBLIC_ROUTES = Object.values(ROUTES.public);
const PRIVATE_ROUTES = Object.values(ROUTES.private);

@observer
class Private extends React.Component<PrivateModuleProps> {
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private businessOwnersStore = container.get<BusinessOwnersStore>(BusinessOwnersStore.diToken);
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  private get routes(): Array<{
    roles: Array<string>;
    routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
  }> {
    const submodules = [
      {
        routeProps: {
          path: ROUTES.private.experiences,
          component: Experiences,
        },
        roles: ACCESS.experiences,
      },
      {
        routeProps: { path: ROUTES.private.profiles, component: Profiles },
        roles: ACCESS.profiles,
      },
      {
        routeProps: {
          path: ROUTES.private.businessOwners,
          component: BusinessOwners,
        },
        roles: ACCESS.businessOwners,
      },
      {
        routeProps: { path: ROUTES.private.dashboard, component: Dashboard },
        roles: ACCESS.dashboard,
      },
      {
        routeProps: { path: ROUTES.private.projects, component: Projects },
        roles: ACCESS.projects,
      },
      {
        routeProps: { path: ROUTES.private.teams, component: Teams },
        roles: ACCESS.teams,
      },
      {
        routeProps: {
          path: ROUTES.private.userDeletion,
          component: UserDeletion,
        },
        roles: ACCESS.userDeletion,
      },
      {
        routeProps: {
          path: ROUTES.private.changePassword,
          component: ChangePassword,
        },
        roles: ACCESS.changePassword,
      },
    ];

    return submodules.filter((module) =>
      module.roles.some((moduleRole) => this.authStore.user.role === moduleRole)
    );
  }

  private handleForbiddenBtnClick = () => {
    this.layoutStore.setCurrentModuleForbiddenState(false);
  };

  private renderNoMatch = () => {
    const { location } = this.props;
    const moduleRoute = (location.pathname.match(/^\/[^\/]+/) || [])[0];

    return <NoMatch forbidden={PRIVATE_ROUTES.some((route) => moduleRoute === route)} />;
  };

  private get modules() {
    const { classes } = this.props;
    const redirectPathname = getDefaultRoute();

    return (
      <Lazyload classes={{ root: classes.moduleLoading }}>
        <Switch>
          {this.routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
            <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
          ))}
          <Redirect exact from={ROUTES.initial} to={redirectPathname} />
          {PUBLIC_ROUTES.map((route) => (
            <Redirect exact key={route} from={route} to={redirectPathname} />
          ))}
          <Route render={this.renderNoMatch} />
        </Switch>
      </Lazyload>
    );
  }

  render() {
    const { classes } = this.props;
    const { user } = this.authStore;
    const { businessOwner } = this.businessOwnersStore;
    const { subheaderConfig, isCurrentModuleForbidden } = this.layoutStore;
    const shouldRenderSubheader = Boolean(subheaderConfig);
    const headerHeight = shouldRenderSubheader ? 96 : 48;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {isCurrentModuleForbidden && (
            <NoMatch
              forbidden
              classes={{ root: classes.forbiddenMessage }}
              btnProps={{
                onClick: this.handleForbiddenBtnClick,
              }}
            />
          )}
          <Header
            classes={{
              navigation: classes.navigation,
              subheader: classes.subheader,
            }}
            navigationProps={{
              user,
              businessOwner,
              links: getNavigationLinks(),
              logout: this.authStore.logout,
            }}
            style={{
              height: headerHeight,
            }}
            shouldRenderSubheader={shouldRenderSubheader}
            subheaderProps={subheaderConfig}
          />
          <section className={classes.main} style={{ height: `calc(100% - ${headerHeight}px` }}>
            <div className={classes.module}>
              {this.appCrashed ? <ErrorHandler /> : this.modules}
            </div>
            <Footer />
          </section>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Private);
