import { createStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default ({ spacing, shortcuts, borderStyles, palette: { colors } }: Theme) => {
  const commonSupportBtnStyles: CSSProperties = {
    color: colors.primary,
    opacity: 0.8,
    fontSize: 12,
    fontWeight: 400,
    cursor: 'pointer',
  };
  const commonAvatarStyles: CSSProperties = {
    cursor: 'pointer',
    margin: 0,
    border: `3px solid ${colors.white}`,
  };

  return createStyles({
    root: {},
    smallAvatar: {
      ...commonAvatarStyles,
      width: 32,
      height: 32,
    },
    menuWrapper: {
      width: 250,
      marginTop: spacing(10),
      marginLeft: -spacing(2),
    },
    content: {
      padding: spacing(4),
    },
    bigAvatar: {
      ...commonAvatarStyles,
      width: 40,
      height: 40,
    },
    email: {
      marginLeft: spacing(4),
      fontSize: 14,
    },
    authBtnsWrapper: {
      marginTop: spacing(3),
    },
    logoutBtn: {
      marginRight: spacing(5),
    },
    divider: {
      border: borderStyles.divider,
      margin: spacing(4, 0, 6, 0),
    },
    supportBtnsWrapper: {
      margin: spacing(0, 0, 4, 3),
    },
    supportBtn: {
      ...commonSupportBtnStyles,
    },
    tutorialsBtn: {
      ...commonSupportBtnStyles,
      marginLeft: spacing(13),
    },
  });
};
