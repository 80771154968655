import { injectable } from 'inversify';

import {
  ExportSuccessResponse,
  LegacyListRequestResponse,
  ListRequestParams,
} from '@shared/types/services';
import Experience, { ExperienceDTO } from '@shared/models/experience';
import { Id } from '@shared/types/common';
import { getHTTPClient, HttpClientType } from '@core/http-client';
import { denormalizeLegacyPagination, normalizeLegacyPagination } from '@shared/utils/services';
import { ExportResourceType, ExportUserRole } from '@shared/constants/export';

const $http = getHTTPClient(HttpClientType.backend);

@injectable()
export default class ExperiencesService {
  static diToken = Symbol('experiences-service');

  private urlPrefix = '/reports/';

  async getList(params: ListRequestParams) {
    const { data } = await $http.get<LegacyListRequestResponse<ExperienceDTO>>(this.urlPrefix, {
      params: {
        ...normalizeLegacyPagination(params.page),
        ...params.sorting,
      },
    });

    return {
      list: data.items.map((dto) => new Experience(dto)),
      pagination: denormalizeLegacyPagination(data.pagination),
    };
  }

  async getExperience(id: Id) {
    const {
      data: { report },
    } = await $http.get(`${this.urlPrefix}${id}/`);

    return new Experience(report);
  }

  async exportExperiences(resource: ExportResourceType) {
    const { data } = await $http.post<ExportSuccessResponse>('export/data/', {
      resource,
      export: 'experiences',
      type: ExportUserRole.admin,
    });

    return data;
  }
}
