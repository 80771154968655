import { createMuiTheme, fade, TransitionsOptions } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';

import { browser } from '@shared/utils/browser';

export const colors = {
  primary: '#212F44',
  secondary: '#373633',
  red: '#DA291C',
  redLight: '#FF5C50',
  warning: '#FFC107',
  error: '#DC3545',
  black: '#000',
  white: '#FFFFFF',
  yellow: '#F2C600',
  orange: '#FF8701',
  purple: '#B206FF',
  purpleLight: '#B950EB',
  grey: '#7D7D7D',
  grey2: '#8D9AAE',
  grey3: '#B6BDC9',
  grey4: '#414141',
  grey5: '#313131',
  grey6: '#1A1A1A',
  greyLight: '#DEE0E4',
  greyLight2: '#C4CFD0',
  greyLight3: '#F1F3F5',
  greyLight4: '#F2F2F2',
  greyLight5: '#EDEDED',
  greyLight6: '#626C7B',
  greyLight7: '#99A2AE',
  greyLight8: '#F9F9F9',
  greyLight9: '#D8DEE8',
  greyLight10: '#F0F1F2',
  greyLight11: '#DADADA',
  greyLight12: '#F5F7FA',
  greyLight13: '#212F44',
  whitePale: '#F9FBFE',
  blue: '#0076FF',
  blueLight: '#0087D2',
  green: '#78BE20',
  greenLight: '#B2DE00',
  greenLight2: '#13A389',
  greenLight3: '#0EB20E',
  disabled: '#EFEFEF',
};

export const borderStyles = {
  primarySolid: `1px solid ${fade('#000', 0.3)}`,
  primarySolidHover: '1px solid transparent',
  secondarySolid: '',
  primaryDashed: `1px dashed ${fade('#000', 0.3)}`,
  primaryDashedHover: '1px dashed transparent',
  secondaryDashed: '',
  divider: `1px solid ${colors.greyLight}`,
};

const transitions: TransitionsOptions = {
  duration: {
    standard: 0.3,
  },
};

export enum FieldMargin {
  none = 0,
  small = 12,
  medium = 16,
  large = 24,
}

export const opacity = {
  disabled: 0.6,
  hover: 0.8,
};

const commonBorderStyles: CSSProperties = {
  color: colors.black,
  transition: `${transitions.duration?.standard}s`,
  fontWeight: 500,
  cursor: 'pointer',
  lineHeight: 1.2,
};

export const shortcuts = {
  borderPrimarySolid: {
    ...commonBorderStyles,
    borderBottom: borderStyles.primarySolid,

    '&:hover': {
      borderBottom: borderStyles.primarySolidHover,
    },
  },
  borderPrimaryDashed: {
    ...commonBorderStyles,
    borderBottom: borderStyles.primaryDashed,

    '&:hover': {
      borderBottom: borderStyles.primaryDashedHover,
    },
  },
  disabled: {
    opacity: opacity.disabled,
    pointerEvents: 'none' as CSSProperties['pointerEvents'],
  },
  errorText: {
    fontSize: 11,
    fontWeight: 500,
    width: '100%',
    color: colors.error,
    position: 'absolute' as CSSProperties['position'],
  },
  link: {
    fontSize: 14,
    color: colors.green,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },
};

const fontFamily = '"Roboto", sans-serif';

const spacing = 4;

export const radius = {
  containers: 8,
  controls: 4,
};

export const scrollStyles: CSSProperties = {
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: radius.containers,
    backgroundColor: colors.greyLight4,
    margin: 1,
  },

  '&::-webkit-scrollbar-thumb': {
    background: colors.greyLight2,
    borderRadius: radius.containers,
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: colors.grey,
  },

  scrollbarColor: `${colors.greyLight2} ${colors.greyLight2}`,
  scrollbarWidth: 'thin',
};

export const boxShadow = {
  containers: {
    primary: '0px 4px 9px rgba(140, 140, 140, 0.11)',
    secondary: '0px 1px 9px rgba(131, 131, 131, 0.25)',
    tetriary: '0px 4px 7px rgba(190, 190, 190, 0.25)',
  },
};

export const getHoverStyles = (styles: {
  supportedDeviceStyles?: CSSProperties;
  defaultStyles?: CSSProperties;
}) => {
  let hoverNoneStyles: CSSProperties = {};

  if (styles.defaultStyles) {
    const defaultStyles = styles.defaultStyles as CSSProperties;

    hoverNoneStyles = Object.keys(defaultStyles).reduce((acc, key) => {
      acc[key] = `${defaultStyles[key]} !important`;

      return acc;
    }, {});
  }

  return {
    ...styles.defaultStyles,

    '@media (hover: hover)': {
      '&:hover': styles.supportedDeviceStyles,
    },

    '@media (hover: none)': {
      '&:hover': hoverNoneStyles,
    },

    ...(browser?.name == 'ie'
      ? {
          '&:hover': styles.supportedDeviceStyles,
        }
      : {}),
  };
};

const buttonPrimaryOutlinedBorderColor = colors.green;

export default createMuiTheme({
  radius,
  spacing,
  opacity,
  boxShadow,
  shortcuts,
  transitions,
  borderStyles,
  palette: {
    colors,
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily,
  },
  props: {
    MuiTooltip: {
      placement: 'top-start',
      TransitionComponent: Fade,
    },
    MuiTabs: {
      scrollButtons: 'auto',
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiPopover: {
      TransitionComponent: Fade,
    },
    MuiRadio: {
      color: 'primary',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: spacing * 2,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.greyLight3,
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary: {
        '&:hover': {
          borderColor: buttonPrimaryOutlinedBorderColor,
        },
      },
    },
    MuiFab: {
      root: {
        height: 'auto',
        padding: 1,
        backgroundColor: 'transparent',
        minHeight: 0,
        width: 'auto',
        boxShadow: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 36,
        fontSize: 14,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        borderRadius: radius.controls,
        fontWeight: 500,
        textTransform: 'none',
        boxShadow: 'none',

        '&$disabled': {
          opacity: opacity.disabled,
        },
      },
      startIcon: {
        marginLeft: 0,
      },
      label: {
        fontWeight: 500,
        whiteSpace: 'nowrap',
      },
      sizeSmall: {
        fontSize: 12,
      },
      sizeLarge: {
        fontSize: 15,
      },
      containedSizeSmall: {
        height: 32,
        padding: `0 ${spacing * 4}px`,
      },
      containedSizeLarge: {
        height: 40,
        padding: `0 ${spacing * 6}px`,
      },
      contained: {
        boxShadow: 'none',
        color: colors.white,

        ...getHoverStyles({
          supportedDeviceStyles: {
            boxShadow: 'none',
            backgroundColor: colors.grey,
          },
        }),

        '&$disabled': {
          color: fade(colors.grey, 0.5),
          backgroundColor: colors.disabled,
        },
      },
      containedPrimary: {
        ...getHoverStyles({
          supportedDeviceStyles: {
            backgroundColor: fade(colors.green, 0.9),
          },
          defaultStyles: {
            backgroundColor: colors.green,
          },
        }),

        '&$disabled': {
          color: colors.whitePale,
          backgroundColor: colors.grey3,
        },
      },
      outlinedSizeSmall: {
        height: 32,
        padding: `0 ${spacing * 4}px`,
      },
      outlinedSizeLarge: {
        height: 40,
        padding: `0 ${spacing * 6}px`,
      },
      outlined: {
        padding: `0 ${spacing * 4}px`,
        borderColor: buttonPrimaryOutlinedBorderColor,
      },
      outlinedPrimary: {
        color: colors.green,

        ...getHoverStyles({
          supportedDeviceStyles: {
            backgroundColor: colors.green,
            borderColor: colors.green,
            color: 'white',
          },
          defaultStyles: {
            borderColor: colors.green,
          },
        }),

        '&$disabled': {
          borderColor: colors.grey3,
          color: colors.grey3,
        },
      },
      containedSecondary: {
        backgroundColor: colors.green,

        ...getHoverStyles({
          supportedDeviceStyles: {
            backgroundColor: colors.green,
          },
        }),

        '&$disabled': {
          color: colors.whitePale,
          backgroundColor: colors.green,
        },
      },
      text: {
        ...getHoverStyles({
          supportedDeviceStyles: {
            opacity: 0.9,
          },
        }),
      },
      textSizeLarge: {
        padding: 0,
        height: 'auto',
      },
      textPrimary: {
        color: colors.green,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: boxShadow.containers.primary,
      },
      elevation4: {
        boxShadow: boxShadow.containers.secondary,
      },
      elevation20: {
        boxShadow: boxShadow.containers.primary,
        padding: spacing * 4,
      },
      elevation22: {
        boxShadow: boxShadow.containers.primary,
      },
      rounded: {
        borderRadius: radius.containers,
      },
    },
    MuiInputBase: {
      root: {
        height: 40,
        fontSize: 14,
      },
      input: {
        height: '100%',
        paddingTop: 0,
        paddingBottom: 0,
      },
      multiline: {
        height: 'auto',
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottomWidth: 1,
        },

        '&:before': {
          borderBottom: `1px solid ${colors.greyLight2}`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: colors.white,

        '&$focused $notchedOutline': {
          borderWidth: 1,
        },
      },
      input: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      inputMarginDense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      notchedOutline: {
        borderColor: colors.greyLight2,
        top: -8,
      },
    },
    MuiFilledInput: {
      input: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: colors.white,
        color: colors.primary,
        flexWrap: 'nowrap',
      },
    },
    MuiDialog: {
      paper: {
        padding: `${spacing * 7}px ${spacing * 7}px ${spacing * 8}px`,

        '@media (max-width: 600px)': {
          padding: spacing * 4,
          margin: spacing * 2,
        },
      },
      paperFullScreen: {
        padding: spacing * 4,
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing * 7}px ${spacing * 5}px ${spacing * 7}px ${spacing * 8}px`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: `0px 0px ${spacing * 4}px 0px`,
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing * 6}px 0 0`,
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: colors.white,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: colors.white,
          boxShadow: boxShadow.containers.secondary,
        },
      },
      hover: {},
    },
    MuiTableCell: {
      root: {
        height: 44,
        borderColor: colors.greyLight5,
        transition: `${transitions.duration?.standard}s`,
        padding: `0 0 0 ${spacing * 4}px`,

        '&:last-child': {
          paddingRight: spacing * 4,
        },

        '&$head': {
          backgroundColor: colors.greyLight4,
        },
      },
      head: {
        color: colors.primary,
        textTransform: 'uppercase',
        fontWeight: 600,
        borderBottom: 'none',
        fontSize: 13,
        height: 42,
      },
      alignRight: {
        paddingRight: spacing * 4,
      },
    },
    MuiTablePagination: {
      spacer: {
        flex: 1,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.green,
      },
      colorSecondary: {
        '&$checked': {
          color: colors.green,
        },
      },
      checked: {},
    },
    MuiRadio: {
      root: {
        color: colors.green,
      },
      colorPrimary: {
        '&$checked': {
          color: colors.green,
        },
      },
      checked: {},
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
      },
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        opacity: 0.8,
        minWidth: 'auto',
        minHeight: 'auto',

        '&:hover': {
          opacity: 1,
        },

        '&$selected': {
          opacity: 1,
          fontWeight: 600,
        },

        '@media (min-width: 600px)': {
          minWidth: 'auto',
        },
      },
      wrapper: {
        fontWeight: 'inherit',
      },
    },
    MuiSwitch: {
      colorSecondary: {
        color: 'white',

        '&$checked': {
          color: colors.green,
        },

        '&$checked + $track': {
          backgroundColor: colors.greenLight,
          opacity: 1,
        },
      },
      checked: {},
      track: {},
    },
    MuiFormLabel: {
      root: {
        color: colors.black,
        margin: `0 0 ${spacing * 2}px`,
        fontWeight: 400,
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.green,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colors.green,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: colors.green,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: colors.green,
      },
      thumb: {
        backgroundColor: colors.green,
        borderColor: colors.green,
      },
      noPoint: {
        backgroundColor: colors.green,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: colors.green,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: spacing * 3,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
      },
    },
  },
});
