import * as React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface ButtonProps extends MuiButtonProps {
  text?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  component?: React.ComponentType<any>;
  componentProps?: any;
}

const Button: React.FC<ButtonProps> = ({
  text,
  loading,
  error,
  children,
  component: Component,
  componentProps,
  ...otherProps
}) => {
  const baseProps: MuiButtonProps = {
    variant: 'contained',
    size: 'small',
    disabled: loading,
    color: 'primary',
    style: error ? { backgroundColor: '#DC5A3F', color: 'white' } : {}, // TODO move this logic to classes
    ...otherProps,
  };

  const spinner = loading ? <CircularProgress size={15} style={{ marginLeft: 8 }} /> : null;

  const content = (
    <MuiButton {...baseProps}>
      {text || children} {spinner}
    </MuiButton>
  );

  if (Component) {
    return <Component {...componentProps}>{content}</Component>;
  }

  return content;
};

export default Button;
