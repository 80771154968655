import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

import container from '@core/di';
import ProjectsService, { ShareSurveyData, SurveyCreateEditData } from '@shared/services/projects';
import { UploadService } from '@shared/services';
import { ListRequestParams, Pagination } from '@shared/types/services';
import Project, { ProjectDTO, ProjectStatus } from '@shared/models/project';
import { Id } from '@shared/types/common';
import Survey from '@shared/models/project/survey';
import Team from '@shared/models/team';
import Interview from '@shared/models/project/interview';
import { ExportResourceType } from '@shared/constants/export';
import { SurveyExportType } from '@modules/Private/submodules/Projects/pages/ProjectDetails/pages/SurveyDetails';
import { AppFileType } from '@shared/models/app-file';
import { MultipartUploadFilePart } from '@shared/services/upload';
import { SurveyStatus } from '@shared/models/project/survey';

@injectable()
export default class ProjectsStore {
  static diToken = Symbol('projects-store');

  private service = container.get<ProjectsService>(ProjectsService.diToken);
  private uploadService = container.get<UploadService>(UploadService.diToken);
  @observable private _list: Array<Project> = [];
  @observable private _surveys: Array<Survey> = [];
  @observable private _interviews: Array<Interview> = [];
  @observable private _teams: Array<Team> = [];
  @observable private _surveysPagination: Pagination;
  @observable private _interviewsPagination: Pagination;
  @observable private _projectsPagination: Pagination;
  @observable private _teamsPagination: Pagination;
  @observable loading = {
    list: false,
    project: false,
    surveys: false,
    teams: false,
    interviews: false,
    interview: false,
    surveyMetadata: false,
  };

  @observable error = {
    surveyGet: false,
    surveyMetadataGet: false,
    projectGet: false,
    interviewGet: false,
  };

  @computed get list() {
    return this._list;
  }

  @computed get surveys() {
    return this._surveys;
  }

  @computed get interviews() {
    return this._interviews;
  }

  @computed get teams() {
    return this._teams;
  }

  @computed get projectsPagination() {
    return this._projectsPagination;
  }

  @computed get surveysPagination() {
    return this._surveysPagination;
  }

  @computed get interviewsPagination() {
    return this._interviewsPagination;
  }

  @computed get teamsPagination() {
    return this._teamsPagination;
  }

  getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { list, pagination } = await this.service.getList(params);

      this._list = list;

      if (pagination) {
        this._projectsPagination = pagination;
      }
    } finally {
      this.loading.list = false;
    }
  };

  getProject = async (projectId: Id) => {
    this.error.projectGet = false;
    this.loading.project = true;

    try {
      const project = await this.service.getProject(projectId);

      return project;
    } catch {
      this.error.projectGet = true;
    } finally {
      this.loading.project = false;
    }
  };

  createProject = (project: ProjectDTO) => {
    return this.service.createProject(project);
  };

  editProject = (projectId: Id, data: ProjectDTO) => {
    return this.service.editProject(projectId, data);
  };

  deleteProject = (projectId: Id) => {
    return this.service.deleteProject(projectId);
  };

  pauseProject = (projectId: Id, status: ProjectStatus) => {
    return this.service.pauseProject(projectId, status);
  };

  addTeams = (projectId: Id, teamIds: Array<Id>) => {
    return this.service.addTeams(projectId, teamIds);
  };

  getSurveys = async (projectId: Id, params: ListRequestParams) => {
    this.loading.surveys = true;

    try {
      const { list, pagination } = await this.service.getSurveys(projectId, params);

      this._surveys = list;
      this._surveysPagination = pagination;
    } finally {
      this.loading.surveys = false;
    }
  };

  async getSurvey(surveyId: Id) {
    this.error.surveyGet = false;

    try {
      const data = await this.service.getSurvey(surveyId);

      return data;
    } catch {
      this.error.surveyGet = true;
    }
  }

  async getSurveyMetadata(surveyId: Id) {
    this.error.surveyMetadataGet = false;
    this.loading.surveyMetadata = true;

    try {
      const data = await this.service.getSurveyMetadata(surveyId);

      return data;
    } catch {
      this.error.surveyMetadataGet = true;
    } finally {
      this.loading.surveyMetadata = false;
    }
  }

  getPublicSurvey(token: string) {
    return this.service.getPublicSurvey(token);
  }

  getSurveyInterviews = async (surveyId: Id, params: ListRequestParams) => {
    this.loading.interviews = true;

    try {
      const { list, pagination } = await this.service.getSurveyInterviews(surveyId, params);

      this._interviews = list;
      this._interviewsPagination = pagination;
    } finally {
      this.loading.interviews = false;
    }
  };

  @action getInterview = async (interviewId: Id) => {
    this.error.interviewGet = false;
    this.loading.interview = true;

    try {
      const interview = await this.service.getInterview(interviewId);

      return interview;
    } catch (err) {
      this.error.interviewGet = true;
      throw err;
    } finally {
      this.loading.interview = false;
    }
  };

  @action getTeams = async (projectId: Id, params: ListRequestParams) => {
    this.loading.teams = true;

    try {
      const { list, pagination } = await this.service.getTeams(projectId, params);

      this._teams = list;

      if (pagination) {
        this._teamsPagination = pagination;
      }
    } finally {
      this.loading.teams = false;
    }
  };

  createSurvey = (data: SurveyCreateEditData) => {
    return this.service.createSurvey(data);
  };

  editSurvey = (id: Id, data: SurveyCreateEditData) => {
    return this.service.editSurvey(id, data);
  };

  saveSurveyDraft = (data: SurveyCreateEditData) => {
    return this.service.saveSurveyDraft(data);
  };

  fillInterview = (data, token: string) => {
    return this.service.fillInterview(data, token);
  };

  getLogoUploadLink = () => {
    return this.service.getLogoUploadLink();
  };

  uploadLogo = (link: string, logo: ArrayBuffer, extension: File['type']) => {
    return this.service.uploadLogo(link, logo, extension);
  };

  getSurveyDescriptionMediaMetadata: ProjectsStore['getSurveyMediaMetadata'] = (...args) => {
    return this.getSurveyMediaMetadata(...args);
  };

  getSurveyAnswerMediaMetadata: ProjectsStore['getSurveyMediaMetadata'] = (...args) => {
    return this.getSurveyMediaMetadata(...args);
  };

  private getSurveyMediaMetadata = async (
    file: File,
    fileType: AppFileType,
    config: {
      multiPartUpload: {
        minUploadSize: number;
        chunkSize: number;
        reUploadAttemptsAmount: number;
      };
    }
  ) => {
    const {
      multiPartUpload: { minUploadSize },
    } = config;

    if (file.size < minUploadSize) {
      const { id: singlePartFileId, link } = await this.service.generateSinglePartPresignedUrl(
        fileType
      );

      return {
        uploadUrl: link,
        fileId: singlePartFileId,
      };
    }

    const {
      multiPartUpload: { chunkSize, reUploadAttemptsAmount },
    } = config;
    const {
      id: multiPartFileId,
      objectName,
      uploadId,
      urls,
    } = await this.service.generateMultiPartPresignedUrls(
      {
        file,
        fileType,
        contentType: file.type,
      },
      {
        chunkSize,
      }
    );
    const parts = await this.uploadService.uploadMultiPartFile(file, urls, {
      reUploadAttemptsAmount,
      fileChunkSize: chunkSize,
    });

    return {
      objectName,
      uploadId,
      parts,
      fileId: multiPartFileId,
    };
  };

  completeMultiPartUpload(data: {
    uploadId: string;
    parts: Array<MultipartUploadFilePart>;
    objectName: string;
  }) {
    return this.service.completeMultiPartUpload(data);
  }

  changeSurveyStatus = (surveyId: Id, status: SurveyStatus) => {
    return this.service.changeSurveyStatus(surveyId, status);
  };

  deleteTeam = (projectId: Id, teamId: Id) => {
    return this.service.deleteTeam(projectId, teamId);
  };

  deleteSurvey = (surveyId: Id) => {
    return this.service.deleteSurvey(surveyId);
  };

  deleteUser = () => {
    return this.service.deleteUser();
  };

  exportSurvey = (resource: ExportResourceType, exportType: SurveyExportType, surveyId: Id) => {
    return this.service.exportSurvey(resource, exportType, surveyId);
  };

  exportProjects = (resource: ExportResourceType) => {
    return this.service.exportProjects(resource);
  };

  shareSurvey = (surveyId: Id, data: ShareSurveyData) => {
    return this.service.shareSurvey(surveyId, data);
  };

  duplicateSurvey = (projectId: Id, surveyId: Id) => {
    return this.service.duplicateSurvey(projectId, surveyId);
  };

  resetList = () => {
    this._list = [];
  };

  resetTeams = () => {
    this._teams = [];
  };

  resetSurveys = () => {
    this._surveys = [];
  };

  resetInterviews = () => {
    this._interviews = [];
  };
}
