import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@shared/components/Button';
import DialogTitle from '@shared/components/DialogTitle';

import styles from './Confirmation.styles';

export interface ConfirmationProps extends WithStyles<typeof styles> {}

export type ButtonConfig = { text: string };

interface ConfirmationState {
  open: boolean;
  title: React.ReactNode;
  question: React.ReactNode;
  buttonsConfig?: {
    confirm?: ButtonConfig;
    reject?: ButtonConfig;
  };
  callback: (isConfirmed?: boolean) => void;
}

let showConfirmationFn;

export function showConfirmation(
  { title, question, buttonsConfig }: Partial<ConfirmationState>,
  callback: (isConfirmed: boolean) => void
): void {
  showConfirmationFn({ title, question, buttonsConfig }, callback);
}

class Confirmation extends React.Component<ConfirmationProps, ConfirmationState> {
  readonly state: ConfirmationState = {
    open: false,
    title: '',
    question: '',
    callback: (): void => {},
  };

  componentDidMount() {
    showConfirmationFn = this.show;
  }

  private show = (
    { title, question, buttonsConfig }: Partial<ConfirmationState>,
    callback: (isConfirmed: boolean) => void
  ): void => {
    this.setState({ title, question, buttonsConfig, callback, open: true });
  };

  private terminate = () => {
    this.state.callback(false);
    this.hide();
  };

  private hide = () => {
    this.setState({ open: false });
  };

  private handleYes = () => {
    this.state.callback(true);
    this.hide();
  };

  render() {
    const { classes } = this.props;
    const { open, title, question, buttonsConfig } = this.state;

    return (
      <Dialog open={open} classes={{ paper: classes.root }} onClose={this.hide}>
        <DialogTitle heading={title} />
        {question && <DialogContent className={classes.content}>{question}</DialogContent>}
        <DialogActions>
          <Button
            variant="outlined"
            text={buttonsConfig?.reject?.text || 'Cancel'}
            onClick={this.terminate}
          />
          <Button text={buttonsConfig?.confirm?.text || 'Yes'} onClick={this.handleYes} />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Confirmation);
