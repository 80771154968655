import React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Loading from '@shared/components/Loading';

import styles from './LazyLoad.styles';

export interface LazyLoadProps extends WithStyles<typeof styles> {
  withFallback?: boolean;
  component?: React.ComponentType<any>;
}

class LazyLoad extends React.Component<LazyLoadProps> {
  static defaultProps = {
    withFallback: true,
  };

  render() {
    const { component: Component, classes, children, withFallback, ...otherProps } = this.props;

    return (
      <React.Suspense
        fallback={withFallback ? <Loading classes={{ root: classes.root }} size={40} /> : null}
      >
        {Component ? <Component {...otherProps} /> : children}
      </React.Suspense>
    );
  }
}

export default withStyles(styles)(LazyLoad);
