export enum SystemUserRole {
  admin = 'ROLE_ADMIN',
  businessOwner = 'ROLE_BUSINESS_OWNER',
  mobileUser = 'ROLE_MOBILE_USER',
}

export interface SystemUserDTO {
  id: number;
  role: SystemUserRole;
  email: string;
}

export default class SystemUser {
  id: number;
  role: SystemUserRole;
  email: string;

  constructor(dto: SystemUserDTO) {
    this.update(dto);
  }

  get asJson(): SystemUserDTO {
    return {
      id: this.id,
      role: this.role,
      email: this.email,
    };
  }

  update(newData: Partial<SystemUserDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
