import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      backgroundColor: colors.greyLight8,
      height: '100%',
    },
  });
}
