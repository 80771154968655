import AppFile from '@shared/models/app-file';
import SurveyQuestion, { SurveyQuestionDTO } from './question';
import { SurveyCoordinates, SurveyStatus } from './survey';

export interface SurveyReadDTO {
  coordinates?: Array<SurveyCoordinates>;
  description: string;
  id: string;
  interviewersCount: number;
  interviewsAmount: number;
  interviewsCount: number;
  isDraft?: boolean;
  status: SurveyStatus;
  link?: string;
  questions: Array<SurveyQuestionDTO>;
  teamMembersCount: number;
  title: string;
  useLocation: boolean;
  files: Array<AppFile>;
}

export class SurveyReadModel {
  coordinates?: Array<SurveyCoordinates>;
  description: string;
  id: string;
  interviewersCount: number;
  interviewsAmount: number;
  interviewsCount: number;
  isDraft?: boolean;
  status: SurveyStatus;
  link?: string;
  questions: Array<SurveyQuestionDTO>;
  teamMembersCount: number;
  title: string;
  useLocation: boolean;
  files: Array<AppFile>;

  constructor(dto: Partial<SurveyReadDTO>) {
    this.update(dto);
  }

  get asJson(): SurveyReadDTO {
    return {
      coordinates: this.coordinates,
      description: this.description,
      id: this.id,
      interviewersCount: this.interviewersCount,
      interviewsAmount: this.interviewsAmount,
      interviewsCount: this.interviewsCount,
      isDraft: this.isDraft,
      status: this.status,
      link: this.link,
      questions: this.questions,
      teamMembersCount: this.teamMembersCount,
      title: this.title,
      useLocation: this.useLocation,
      files: this.files,
    };
  }

  update(newData: Partial<SurveyReadDTO>) {
    const { questions = [], ...otherData } = { ...this.asJson, ...newData };

    Object.assign(this, otherData);

    this.questions = questions.map((question) => new SurveyQuestion(question));

    return this.asJson;
  }
}
