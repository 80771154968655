import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@material-ui/core/DialogTitle';

import Flex from '@shared/components/Flex';

import styles from './DialogTitle.styles';

type Classes = Pick<MuiDialogTitleProps, 'classes'> & WithStyles<typeof styles>;

export interface DialogTitleProps extends Classes, Omit<MuiDialogTitleProps, 'classes'> {
  heading?: React.ReactNode;
  onClose?: () => any;
}

class DialogTitle extends React.Component<DialogTitleProps> {
  render() {
    const { classes, heading, onClose, ...otherProps } = this.props;

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...otherProps}>
        <Flex alignItems="center" justify="space-between" wrap="nowrap">
          <h6 className={classes.heading}>{heading}</h6>
          {onClose && (
            <IconButton disableRipple className={classes.closeIcon} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Flex>
      </MuiDialogTitle>
    );
  }
}

export default withStyles(styles)(DialogTitle);
