import { AppFileDTO } from '@shared/models/app-file';
import { SurveyReadDTO, SurveyReadModel } from './survey/read-model';
import { SurveyLocationReadDTO, SurveyLocationReadModel } from './survey/location/read-model';
import { SurveyQuestionOptionDTO } from './survey/question/option';

export enum InterviewSource {
  web = 'web',
  mobile = 'mobile',
}

export interface InterviewAnswer {
  id: string;
  questionId: string;
  text?: string;
  options?: Array<SurveyQuestionOptionDTO>;
  files?: Array<AppFileDTO>;
}

export interface InterviewDTO {
  id: string;
  createdAt: string;
  interviewer: string;
  respondentId: number;
  source: InterviewSource;
  answers?: Array<InterviewAnswer>;
  location?: SurveyLocationReadDTO;
  survey?: SurveyReadDTO;
}

type InterviewAsJson = Omit<InterviewDTO, 'location'> & { location?: SurveyLocationReadModel };

export default class Interview {
  id: string;
  createdAt: string;
  interviewer: string;
  respondentId: number;
  source: InterviewSource;
  answers?: Array<InterviewAnswer>;
  location?: SurveyLocationReadModel;
  survey?: SurveyReadModel;

  constructor(dto: Partial<InterviewDTO>) {
    this.update(dto);
  }

  get asJson(): InterviewAsJson {
    return {
      id: this.id,
      createdAt: this.createdAt,
      interviewer: this.interviewer,
      respondentId: this.respondentId,
      source: this.source,
      location: this.location,
      survey: this.survey?.asJson,
    };
  }

  private updateLocation(location?: SurveyLocationReadDTO) {
    if (location) {
      const shouldCreateLocationInstance = this.location === undefined;

      if (shouldCreateLocationInstance) {
        this.location = new SurveyLocationReadModel(location);

        return;
      }

      this.location?.update(location);
    }
  }

  update(newData: Partial<InterviewDTO>) {
    const { location, ...otherNewData } = newData;
    const { survey, ...otherData } = { ...this.asJson, ...otherNewData };

    Object.assign(this, otherData);

    if (survey) {
      this.survey = new SurveyReadModel(survey);
    }

    this.updateLocation(location);

    return this.asJson;
  }
}
