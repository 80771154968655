import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import styles from './Logo.styles';

interface Props extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const LogoIcon: React.FC<Props> = ({ classes }) => {
  return (
    <SvgIcon
      width="194"
      height="38"
      viewBox="0 0 194 38"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.36049 22.6385L13.6321 2.38379H18.721V36.8454H14.1143L14.5938 14.286L11.1367 30.6042H7.58428L4.12722 14.286L4.60669 36.8454H0V2.38379H5.08888L9.36049 22.6385Z"
        fill="black"
      />
      <path d="M25.093 37.0963V11.4775H29.6997V37.0963H25.093Z" fill="black" />
      <path
        d="M47.3828 2.38379H56.4055C59.9252 2.38379 61.6851 4.35069 61.6851 8.28722V16.7814C61.6851 19.789 60.7725 21.6932 58.9499 22.4941L62.1645 36.8454H57.7976L54.7737 22.9273H51.9895V36.8454H47.3828V2.38379ZM51.9895 18.9908H55.0134C56.3892 18.9908 57.0757 18.1599 57.0757 16.4954V9.05545C57.0757 7.39366 56.3892 6.56005 55.0134 6.56005H51.9895V18.9908Z"
        fill="black"
      />
      <path
        d="M93.1553 36.8454H88.5486V8.28722C88.5486 4.35069 90.3874 2.38379 94.0679 2.38379H97.5249C101.205 2.38379 103.044 4.35069 103.044 8.28722V36.8454H98.4348V24.2213H93.1553V36.8454ZM98.4348 20.0478V8.57599C98.4348 7.23294 97.7647 6.56005 96.4189 6.56005H95.1712C93.8282 6.56005 93.1553 7.23294 93.1553 8.57599V20.0478H98.4348Z"
        fill="black"
      />
      <path d="M119.611 36.8454H108.665V2.38379H113.274V32.6719H119.611V36.8454Z" fill="black" />
      <path
        d="M150.838 6.56005V36.8454H146.232V6.56005H141.816V2.38379H155.254V6.56005H150.838Z"
        fill="black"
      />
      <path
        d="M66.6321 10.1073C66.6321 5.53602 70.337 1.83105 74.9083 1.83105C79.4796 1.83105 83.1846 5.53602 83.1846 10.1073C83.1846 14.6758 79.4796 18.3835 74.9083 18.3835C70.337 18.3835 66.6321 14.6758 66.6321 10.1073ZM74.9111 17.4573C78.9702 17.4573 82.261 14.1664 82.261 10.1073C82.261 6.04546 78.9702 2.75729 74.9111 2.75729C70.8492 2.75729 67.5583 6.04818 67.5583 10.1073C67.5583 14.1664 70.8492 17.4573 74.9111 17.4573Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M67.4221 4.57665C67.4221 4.57665 67.4603 4.51944 67.5311 4.41319C67.6046 4.30695 67.6973 4.14349 67.8498 3.95824C67.9915 3.7621 68.1822 3.53871 68.4083 3.29081C68.5282 3.17094 68.6535 3.04289 68.787 2.90941C68.9286 2.78409 69.0757 2.6506 69.2365 2.51712C69.5634 2.25831 69.9257 1.98317 70.3425 1.74616C70.7539 1.49825 71.2115 1.27487 71.7046 1.09234C72.195 0.901646 72.7235 0.75726 73.2656 0.656463C73.8132 0.555666 74.3635 0.514803 74.9438 0.503906C75.475 0.512079 76.0661 0.563839 76.6055 0.664635C77.1477 0.770881 77.6762 0.917994 78.1638 1.11141C78.6542 1.29939 79.1118 1.52277 79.5232 1.7734C79.94 2.01314 80.3023 2.28829 80.6265 2.54981C80.7845 2.68603 80.9344 2.81951 81.0733 2.94482C81.2068 3.08104 81.3321 3.20908 81.4492 3.32894C81.6754 3.57957 81.8633 3.80568 82.0023 3.9991C82.1521 4.18708 82.2475 4.35326 82.3183 4.45678C82.3891 4.56303 82.4245 4.62023 82.4245 4.62023C82.4245 4.62023 82.3782 4.57119 82.291 4.47585C82.2039 4.38322 82.0813 4.24156 81.9096 4.07538C81.7462 3.90375 81.531 3.71306 81.2858 3.4924C81.1577 3.38888 81.0215 3.27991 80.8799 3.16277C80.7328 3.0538 80.5748 2.9421 80.414 2.82224C80.0789 2.60157 79.7112 2.36729 79.3025 2.16297C78.8966 1.95048 78.4498 1.77068 77.9813 1.6045C77.5127 1.4356 77.0087 1.33208 76.4993 1.23128C75.979 1.14411 75.4804 1.10596 74.922 1.10052C74.4098 1.10324 73.8704 1.13593 73.361 1.2231C72.8515 1.31845 72.3448 1.41925 71.8763 1.58543C71.4077 1.74616 70.9582 1.92596 70.5523 2.13572C70.1409 2.33732 69.7731 2.56888 69.4381 2.78954C69.2719 2.90123 69.1193 3.02111 68.9695 3.12735C68.8251 3.24177 68.6889 3.35073 68.5609 3.45425C68.3157 3.67219 68.1005 3.86289 67.9343 4.03452C67.7627 4.19797 67.6373 4.34236 67.5502 4.43498C67.4684 4.52488 67.4221 4.57665 67.4221 4.57665Z"
        fill="white"
      />
      <path
        d="M120.728 10.1073C120.728 5.53602 124.433 1.83105 129.007 1.83105C133.575 1.83105 137.283 5.53602 137.283 10.1073C137.283 14.6758 133.578 18.3835 129.007 18.3835C124.433 18.3835 120.728 14.6758 120.728 10.1073ZM129.007 17.4573C133.066 17.4573 136.357 14.1664 136.357 10.1073C136.357 6.04546 133.068 2.75729 129.007 2.75729C124.945 2.75729 121.654 6.04818 121.654 10.1073C121.654 14.1664 124.945 17.4573 129.007 17.4573Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M121.515 4.57665C121.515 4.57665 121.553 4.51944 121.624 4.41319C121.697 4.30695 121.79 4.14349 121.943 3.95824C122.084 3.7621 122.275 3.53871 122.501 3.29081C122.621 3.17094 122.746 3.04289 122.88 2.90941C123.021 2.78409 123.168 2.6506 123.329 2.51712C123.656 2.25831 124.018 1.98317 124.435 1.74616C124.847 1.49825 125.304 1.27487 125.797 1.09234C126.288 0.901646 126.816 0.75726 127.358 0.656463C127.906 0.555666 128.456 0.514803 129.037 0.503906C129.568 0.512079 130.159 0.563839 130.698 0.664635C131.24 0.770881 131.769 0.917994 132.257 1.11141C132.747 1.29939 133.205 1.52277 133.616 1.7734C134.033 2.01314 134.395 2.28829 134.719 2.54981C134.877 2.68603 135.027 2.81951 135.166 2.94482C135.3 3.08104 135.425 3.20908 135.542 3.32894C135.768 3.57957 135.956 3.80296 136.095 3.9991C136.245 4.18708 136.34 4.35326 136.411 4.45678C136.482 4.56303 136.517 4.62023 136.517 4.62023C136.517 4.62023 136.471 4.57119 136.384 4.47585C136.297 4.38322 136.174 4.24156 136.002 4.07538C135.839 3.90375 135.624 3.71306 135.379 3.4924C135.251 3.38888 135.114 3.27991 134.973 3.16277C134.826 3.0538 134.668 2.9421 134.507 2.82224C134.172 2.60157 133.804 2.36729 133.395 2.16297C132.989 1.95048 132.543 1.77068 132.074 1.6045C131.605 1.4356 131.101 1.33208 130.592 1.23128C130.072 1.14411 129.573 1.10596 129.015 1.10052C128.503 1.10324 127.963 1.13593 127.454 1.2231C126.944 1.31845 126.438 1.41925 125.969 1.58543C125.5 1.74616 125.051 1.92596 124.645 2.13572C124.234 2.33732 123.866 2.56888 123.531 2.78954C123.365 2.90123 123.212 3.02111 123.062 3.12735C122.918 3.24177 122.782 3.35073 122.654 3.45425C122.408 3.67219 122.193 3.86289 122.027 4.03452C121.855 4.19797 121.73 4.34236 121.643 4.43498C121.561 4.52488 121.515 4.57665 121.515 4.57665Z"
        fill="white"
      />
      <path
        d="M122.733 10.1071C122.733 6.6419 125.541 3.83594 129.004 3.83594C132.466 3.83594 135.275 6.64463 135.275 10.1071C135.275 13.5696 132.466 16.3783 129.004 16.3783C125.541 16.3783 122.733 13.5696 122.733 10.1071Z"
        fill="url(#paint2_radial)"
      />
      <g mix-blend-mode="multiply">
        <path
          d="M129.004 16.3782C132.439 16.3782 135.223 13.6158 135.27 10.1942C134.395 9.92445 133.466 9.77734 132.502 9.77734C129.922 9.77734 127.587 10.8234 125.896 12.5152C125.343 13.0137 124.572 13.5177 123.899 13.7411C125.035 15.3348 126.895 16.3782 129.004 16.3782Z"
          fill="url(#paint2_radial)"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M127.718 8.90773C127.718 9.32454 127.38 9.66507 126.961 9.66507C126.544 9.66507 126.203 9.32726 126.203 8.90773C126.203 8.49092 126.544 8.15039 126.961 8.15039C127.38 8.15039 127.718 8.49092 127.718 8.90773Z"
          fill="#666666"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M130.29 8.90773C130.29 9.32454 130.627 9.66507 131.047 9.66507C131.464 9.66507 131.801 9.32726 131.801 8.90773C131.801 8.49092 131.464 8.15039 131.047 8.15039C130.627 8.15039 130.29 8.49092 130.29 8.90773Z"
          fill="#666666"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M120.747 10.1073C120.747 5.53602 124.452 1.83105 129.023 1.83105C133.594 1.83105 137.299 5.53602 137.299 10.1073C137.299 14.6758 133.594 18.3835 129.023 18.3835C124.452 18.3835 120.747 14.6758 120.747 10.1073ZM129.023 17.4573C133.085 17.4573 136.373 14.1664 136.373 10.1073C136.373 6.04546 133.082 2.75729 129.023 2.75729C124.964 2.75729 121.673 6.04818 121.673 10.1073C121.673 14.1664 124.964 17.4573 129.023 17.4573Z"
          fill="url(#paint4_linear)"
        />
      </g>
      <path
        d="M159.722 10.1073C159.722 5.53602 163.427 1.83105 167.998 1.83105C172.57 1.83105 176.275 5.53602 176.275 10.1073C176.275 14.6758 172.567 18.3835 167.998 18.3835C163.424 18.3835 159.722 14.6758 159.722 10.1073ZM167.998 17.4573C172.058 17.4573 175.348 14.1664 175.348 10.1073C175.348 6.04546 172.058 2.75729 167.998 2.75729C163.937 2.75729 160.646 6.04818 160.646 10.1073C160.646 14.1664 163.937 17.4573 167.998 17.4573Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M160.507 4.57665C160.507 4.57665 160.545 4.51944 160.616 4.41319C160.689 4.30695 160.782 4.14349 160.935 3.95824C161.076 3.7621 161.267 3.53871 161.493 3.29081C161.613 3.17094 161.738 3.04289 161.872 2.90941C162.013 2.78409 162.16 2.6506 162.321 2.51712C162.648 2.25831 163.01 1.98317 163.427 1.74616C163.839 1.49825 164.296 1.27487 164.789 1.09234C165.28 0.901646 165.808 0.75726 166.35 0.656463C166.898 0.555666 167.448 0.514803 168.028 0.503906C168.56 0.512079 169.151 0.563839 169.69 0.664635C170.232 0.770881 170.761 0.917994 171.248 1.11141C171.739 1.29939 172.197 1.52277 172.608 1.7734C173.025 2.01314 173.387 2.28829 173.711 2.54981C173.869 2.68603 174.019 2.81951 174.158 2.94482C174.291 3.08104 174.417 3.20908 174.534 3.32894C174.76 3.57957 174.948 3.80568 175.087 3.9991C175.237 4.18708 175.332 4.35326 175.403 4.45678C175.474 4.56303 175.509 4.62023 175.509 4.62023C175.509 4.62023 175.463 4.56847 175.376 4.47585C175.289 4.38322 175.166 4.24156 174.994 4.07538C174.831 3.90375 174.616 3.71306 174.37 3.4924C174.242 3.38888 174.106 3.27991 173.965 3.16277C173.817 3.0538 173.659 2.9421 173.499 2.82224C173.164 2.60157 172.796 2.36729 172.387 2.16297C171.981 1.95048 171.535 1.77068 171.066 1.6045C170.597 1.4356 170.093 1.33208 169.584 1.23128C169.064 1.14411 168.565 1.10596 168.007 1.10052C167.494 1.10324 166.955 1.13593 166.446 1.2231C165.936 1.31845 165.43 1.41925 164.961 1.58543C164.492 1.74616 164.043 1.92596 163.637 2.13572C163.226 2.33732 162.858 2.56888 162.523 2.78954C162.357 2.90123 162.204 3.02111 162.054 3.12735C161.91 3.24177 161.774 3.35073 161.646 3.45425C161.4 3.67219 161.185 3.86289 161.019 4.03452C160.847 4.19797 160.722 4.34236 160.635 4.43498C160.553 4.52488 160.507 4.57665 160.507 4.57665Z"
        fill="white"
      />
      <path
        d="M161.727 10.1071C161.727 6.6419 164.533 3.83594 167.999 3.83594C171.464 3.83594 174.27 6.64463 174.27 10.1071C174.27 13.5696 171.464 16.3783 167.999 16.3783C164.533 16.3783 161.727 13.5696 161.727 10.1071Z"
        fill="url(#paint6_radial)"
      />
      <g mix-blend-mode="multiply">
        <path
          d="M167.996 16.3782C171.428 16.3782 174.215 13.6158 174.264 10.1942C173.39 9.92445 172.458 9.77734 171.496 9.77734C168.917 9.77734 166.582 10.8234 164.89 12.5152C164.337 13.0137 163.566 13.5177 162.893 13.7411C164.029 15.3348 165.89 16.3782 167.996 16.3782Z"
          fill="url(#paint6_radial)"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M159.739 10.1073C159.739 5.53602 163.443 1.83105 168.015 1.83105C172.586 1.83105 176.294 5.53602 176.294 10.1073C176.294 14.6758 172.586 18.3835 168.015 18.3835C163.443 18.3835 159.739 14.6758 159.739 10.1073ZM168.015 17.4573C172.074 17.4573 175.367 14.1664 175.367 10.1073C175.367 6.04546 172.077 2.75729 168.015 2.75729C163.956 2.75729 160.665 6.04818 160.665 10.1073C160.665 14.1664 163.956 17.4573 168.015 17.4573Z"
          fill="url(#paint8_linear)"
        />
      </g>
      <path
        d="M24.1641 5.56556C24.1641 3.67221 25.7005 2.13574 27.5939 2.13574C29.4872 2.13574 31.0237 3.67221 31.0237 5.56556C31.0237 7.45891 29.4872 8.99539 27.5939 8.99539C25.7005 8.99539 24.1641 7.45891 24.1641 5.56556Z"
        fill="black"
      />
      <path
        d="M68.6399 10.1071C68.6399 6.6419 71.4459 3.83594 74.9111 3.83594C78.3736 3.83594 81.1796 6.64463 81.1796 10.1071C81.1796 13.5696 78.3709 16.3783 74.9111 16.3783C71.4459 16.3783 68.6399 13.5696 68.6399 10.1071Z"
        fill="url(#paint9_radial)"
      />
      <g mix-blend-mode="multiply">
        <path
          d="M74.9126 16.3782C78.3452 16.3782 81.1321 13.6158 81.1784 10.1942C80.3039 9.92445 79.3722 9.77734 78.4105 9.77734C75.8307 9.77734 73.496 10.8234 71.8042 12.5152C71.2512 13.0137 70.4803 13.5177 69.8074 13.7411C70.9434 15.3348 72.8041 16.3782 74.9126 16.3782Z"
          fill="url(#paint9_radial)"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M74.9078 13.5127C73.8426 13.5127 72.8564 13.0278 72.2081 12.1833C72.0746 12.0062 72.1073 11.7583 72.2816 11.6221C72.456 11.4886 72.7066 11.5213 72.8428 11.6957C73.3386 12.344 74.0932 12.7145 74.9078 12.7145C75.7005 12.7145 76.4415 12.3604 76.9373 11.742C77.079 11.5704 77.3296 11.5431 77.5013 11.6821C77.6729 11.821 77.7001 12.0716 77.5612 12.2433C76.9128 13.0469 75.943 13.5127 74.9078 13.5127Z"
          fill="#808080"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M73.6269 8.90773C73.6269 9.32454 73.2891 9.66507 72.8723 9.66507C72.4555 9.66507 72.115 9.32726 72.115 8.90773C72.115 8.49092 72.4528 8.15039 72.8723 8.15039C73.2864 8.15039 73.6269 8.49092 73.6269 8.90773Z"
          fill="#808080"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M76.1943 8.90773C76.1943 9.32454 76.5322 9.66507 76.9517 9.66507C77.3685 9.66507 77.709 9.32726 77.709 8.90773C77.709 8.49092 77.3685 8.15039 76.9517 8.15039C76.5322 8.15039 76.1943 8.49092 76.1943 8.90773Z"
          fill="#808080"
        />
      </g>
      <g mix-blend-mode="multiply">
        <path
          d="M66.6331 10.1073C66.6331 5.53602 70.338 1.83105 74.9093 1.83105C79.4806 1.83105 83.1855 5.53602 83.1855 10.1073C83.1855 14.6758 79.4806 18.3835 74.9093 18.3835C70.338 18.3835 66.6331 14.6758 66.6331 10.1073ZM74.912 17.4573C78.9711 17.4573 82.262 14.1664 82.262 10.1073C82.262 6.04546 78.9711 2.75729 74.912 2.75729C70.8502 2.75729 67.5593 6.04818 67.5593 10.1073C67.5593 14.1664 70.8502 17.4573 74.912 17.4573Z"
          fill="url(#paint11_linear)"
        />
      </g>
      <path
        d="M74.9243 20.9066C72.8539 20.9066 70.9197 20.3318 69.2688 19.332C71.9603 23.6391 74.2269 29.0331 74.8998 35.6748H74.9189C75.5918 29.0467 77.8529 23.6609 80.5363 19.3593C78.8935 20.34 76.9757 20.9066 74.9243 20.9066Z"
        fill="#74AF0D"
      />
      <path
        d="M84.9366 10.0013C84.9366 9.33114 84.8685 8.68005 84.7432 8.04802C83.7925 3.48492 79.7524 0.0605469 74.9114 0.0605469C71.8113 0.0605469 69.0407 1.46626 67.1964 3.67289C65.7852 5.39734 64.938 7.60124 64.938 10.0013C64.938 11.2681 65.1832 12.4722 65.6109 13.5891C66.044 14.5017 66.6733 15.4389 67.4988 16.6702C69.3295 18.7107 71.9774 20.002 74.9359 20.002C77.7828 20.002 80.3436 18.806 82.1661 16.8991C83.2994 15.2073 84.0812 14.0686 84.5225 12.84C84.7895 11.9382 84.9366 10.9902 84.9366 10.0013ZM74.9114 18.3838C70.3402 18.3838 66.6325 14.6761 66.6325 10.1075C66.6325 5.53627 70.3374 1.8313 74.9114 1.8313C79.4827 1.8313 83.1877 5.53627 83.1877 10.1075C83.185 14.6761 79.48 18.3838 74.9114 18.3838Z"
        fill="#74AF0D"
      />
      <path
        d="M84.5225 12.8398C84.8112 12.0307 84.9556 11.1807 84.9556 10.1074C84.9556 9.4018 84.8821 8.71257 84.7431 8.04785C84.8684 8.67988 84.9365 9.33369 84.9365 10.0011C84.9365 10.99 84.7894 11.9381 84.5225 12.8398Z"
        fill="white"
      />
      <path
        d="M67.1962 3.67188C65.7415 5.41539 64.8643 7.65744 64.8643 10.1065C64.8643 11.515 65.1094 12.5366 65.6107 13.5881C65.183 12.4739 64.9378 11.2671 64.9378 10.0003C64.9378 7.60022 65.7851 5.39632 67.1962 3.67188Z"
        fill="white"
      />
      <path
        d="M128.99 20.9059C126.939 20.9059 125.018 20.3392 123.375 19.3558C126.059 23.6601 128.32 29.0459 128.993 35.674H129.015C129.688 29.035 131.957 23.641 134.646 19.334C132.995 20.3311 131.061 20.9059 128.99 20.9059Z"
        fill="#FFC000"
      />
      <path
        d="M139.005 10.0011C139.005 8.30389 138.58 6.70749 137.833 5.30723C136.13 2.17981 132.815 0.0576172 129.007 0.0576172C125.198 0.0576172 121.885 2.17708 120.18 5.29906C119.431 6.70205 119.006 8.30117 119.006 9.99838C119.006 11.1453 119.207 12.2431 119.564 13.2702C120.011 14.3136 120.711 15.3461 121.67 16.779C123.498 18.7541 126.103 19.9991 129.004 19.9991C131.905 19.9991 134.51 18.7568 136.335 16.7817C137.299 15.3433 137.999 14.3081 138.449 13.2593C138.803 12.2404 139.005 11.1453 139.005 10.0011ZM129.007 18.3836C124.435 18.3836 120.728 14.6759 120.728 10.1073C120.728 5.53607 124.433 1.8311 129.007 1.8311C133.575 1.8311 137.283 5.53607 137.283 10.1073C137.28 14.6759 133.575 18.3836 129.007 18.3836Z"
        fill="#FFC000"
      />
      <path
        d="M138.449 13.2644C138.852 12.3218 139.051 11.371 139.051 10.107C139.051 8.3689 138.609 6.73435 137.833 5.30957C138.579 6.70983 139.004 8.30624 139.004 10.0034C139.004 11.1449 138.803 12.24 138.449 13.2644Z"
        fill="white"
      />
      <path
        d="M120.18 5.30176C119.401 6.72926 118.957 8.36653 118.957 10.1073C118.957 11.3768 119.158 12.3303 119.562 13.2729C119.205 12.2459 119.003 11.148 119.003 10.0011C119.006 8.30387 119.431 6.70474 120.18 5.30176Z"
        fill="white"
      />
      <path
        d="M177.997 10.0003C177.997 8.3004 177.569 6.69855 176.82 5.29829C175.114 2.17631 171.804 0.0595703 167.996 0.0595703C164.187 0.0595703 160.875 2.17903 159.169 5.30101C158.42 6.70127 157.995 8.3004 157.995 10.0003C157.995 11.1472 158.197 12.2451 158.554 13.2694C159 14.3155 159.701 15.348 160.662 16.7837C162.49 18.7588 165.095 20.001 167.996 20.001C170.897 20.001 173.501 18.7588 175.329 16.7837C176.288 15.3535 176.989 14.321 177.435 13.2776C177.792 12.2505 177.997 11.1527 177.997 10.0003ZM167.999 18.3828C163.427 18.3828 159.722 14.6751 159.722 10.1066C159.722 5.5353 163.427 1.83033 167.999 1.83033C172.57 1.83033 176.275 5.5353 176.275 10.1066C176.275 14.6751 172.567 18.3828 167.999 18.3828Z"
        fill="#D60812"
      />
      <path
        d="M167.985 20.9059C165.931 20.9059 164.013 20.3392 162.37 19.3558C165.053 23.6601 167.315 29.0459 167.988 35.674H168.007C168.682 29.035 170.949 23.641 173.638 19.334C171.987 20.3311 170.052 20.9059 167.985 20.9059Z"
        fill="#D60812"
      />
      <path
        d="M177.435 13.2808C177.841 12.3355 178.043 11.3793 178.043 10.1071C178.043 8.36632 177.599 6.72633 176.82 5.29883C177.569 6.70181 177.996 8.30094 177.996 10.0009C177.996 11.1532 177.792 12.2511 177.435 13.2808Z"
        fill="white"
      />
      <path
        d="M159.172 5.30176C158.393 6.72926 157.951 8.36653 157.951 10.1073C157.951 11.3741 158.15 12.3276 158.556 13.2729C158.202 12.2458 157.998 11.1507 157.998 10.0038C157.998 8.30387 158.423 6.70474 159.172 5.30176Z"
        fill="white"
      />
      <g mix-blend-mode="multiply">
        <path
          d="M167.998 11.6895C169.064 11.6895 170.05 12.1744 170.698 13.0189C170.832 13.196 170.799 13.4466 170.625 13.5801C170.45 13.7136 170.2 13.6809 170.063 13.5065C169.565 12.8582 168.813 12.4877 167.996 12.4877C167.203 12.4877 166.462 12.8418 165.963 13.4602C165.824 13.6318 165.574 13.6591 165.402 13.5201C165.228 13.3812 165.201 13.1306 165.34 12.9562C165.993 12.1526 166.96 11.6895 167.998 11.6895Z"
          fill="#666666"
        />
        <path
          d="M166.713 8.90871C166.713 9.32552 166.375 9.66605 165.955 9.66605C165.538 9.66605 165.201 9.32824 165.201 8.90871C165.201 8.4919 165.538 8.15137 165.955 8.15137C166.372 8.15137 166.713 8.4919 166.713 8.90871Z"
          fill="#666666"
        />
        <path
          d="M169.281 8.90871C169.281 9.32552 169.622 9.66605 170.039 9.66605C170.456 9.66605 170.796 9.32824 170.796 8.90871C170.796 8.4919 170.456 8.15137 170.039 8.15137C169.619 8.15137 169.281 8.4919 169.281 8.90871Z"
          fill="#666666"
        />
        <path
          d="M164.07 7.16207C164.217 7.12393 164.362 7.10214 164.52 7.09397C164.672 7.09124 164.827 7.09124 164.98 7.11576C165.133 7.13755 165.288 7.1648 165.435 7.21383C165.585 7.26015 165.735 7.3119 165.874 7.38273C166.018 7.45084 166.154 7.52984 166.285 7.62519C166.353 7.66878 166.416 7.72054 166.476 7.7723C166.538 7.82406 166.598 7.87855 166.655 7.93848C166.77 8.05562 166.876 8.18366 166.963 8.32805C167.056 8.46971 167.124 8.62772 167.178 8.79117L167.217 8.90286L167.173 8.99821C167.004 9.37143 166.764 9.6602 166.508 9.9299C166.244 10.1914 165.961 10.4284 165.637 10.611C165.724 10.4475 165.811 10.2868 165.903 10.1342C165.991 9.97621 166.086 9.8291 166.168 9.6711C166.258 9.52126 166.339 9.3687 166.416 9.21887C166.492 9.06904 166.56 8.9192 166.612 8.77482L166.606 8.98186C166.579 8.92738 166.56 8.87289 166.53 8.81841C166.497 8.76665 166.476 8.70944 166.437 8.6604C166.369 8.55961 166.293 8.45609 166.206 8.36346C166.034 8.17277 165.827 8.00386 165.607 7.8513C165.495 7.77503 165.38 7.69874 165.261 7.63609C165.141 7.57071 165.018 7.50533 164.89 7.45084C164.762 7.39636 164.631 7.33915 164.495 7.29284C164.362 7.24653 164.214 7.20566 164.07 7.16207Z"
          fill="#666666"
        />
        <path
          d="M171.9 7.16227C171.755 7.20586 171.608 7.24672 171.477 7.29576C171.341 7.34207 171.21 7.39929 171.082 7.45377C170.954 7.51098 170.832 7.57636 170.712 7.63902C170.592 7.7044 170.478 7.77796 170.366 7.85423C170.145 8.00679 169.938 8.17569 169.767 8.36638C169.677 8.45901 169.603 8.55981 169.532 8.66333C169.494 8.71237 169.472 8.76958 169.44 8.82134C169.41 8.8731 169.391 8.93031 169.363 8.98479L169.358 8.77775C169.41 8.92213 169.481 9.07197 169.554 9.2218C169.63 9.37163 169.712 9.52419 169.802 9.67403C169.884 9.82931 169.979 9.97914 170.066 10.1371C170.156 10.2924 170.246 10.4504 170.333 10.6139C170.009 10.4314 169.726 10.1944 169.461 9.93282C169.208 9.66312 168.966 9.37163 168.797 9.00114L168.753 8.90579L168.791 8.79409C168.846 8.63064 168.914 8.46991 169.007 8.33098C169.094 8.18659 169.2 8.05855 169.314 7.9414C169.369 7.88147 169.432 7.82699 169.494 7.77523C169.554 7.72074 169.617 7.67171 169.685 7.62812C169.816 7.53549 169.952 7.45377 170.096 7.38566C170.238 7.31483 170.388 7.26307 170.535 7.21675C170.682 7.16772 170.837 7.14048 170.99 7.11869C171.142 7.09417 171.298 7.09417 171.45 7.0969C171.605 7.10234 171.753 7.12414 171.9 7.16227Z"
          fill="#666666"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.399 12.5985C191.104 12.5985 193.298 10.405 193.298 7.69904C193.298 4.99312 191.104 2.79954 188.399 2.79954C185.693 2.79954 183.499 4.99312 183.499 7.69904C183.499 10.405 185.693 12.5985 188.399 12.5985ZM188.399 13.2985C191.491 13.2985 193.998 10.7915 193.998 7.69904C193.998 4.60656 191.491 2.09961 188.399 2.09961C185.306 2.09961 182.799 4.60656 182.799 7.69904C182.799 10.7915 185.306 13.2985 188.399 13.2985Z"
        fill="#8D9AAE"
      />
      <path
        d="M188.496 8.20089H187.657V9.79863H186.744V5.41895H188.458C189.004 5.41895 189.428 5.53423 189.729 5.76481C190.029 5.99288 190.18 6.31994 190.18 6.74601C190.18 7.33498 189.898 7.7435 189.334 7.97157L190.364 9.75728V9.79863H189.386L188.496 8.20089ZM187.657 7.47157H188.458C188.729 7.47157 188.932 7.41267 189.067 7.29488C189.202 7.17458 189.27 7.01668 189.27 6.8212C189.27 6.61819 189.204 6.45654 189.071 6.33624C188.94 6.21594 188.736 6.15578 188.458 6.15578H187.657V7.47157Z"
        fill="#8D9AAE"
      />
      <g mix-blend-mode="multiply">
        <path
          d="M129.043 11.477C130.092 11.6622 130.979 12.3112 131.471 13.2556C131.572 13.4532 131.496 13.6916 131.301 13.7954C131.106 13.8966 130.865 13.8208 130.761 13.6254C130.386 12.9007 129.707 12.4047 128.905 12.2631C128.124 12.1253 127.333 12.3452 126.737 12.868C126.568 13.0124 126.316 12.9957 126.171 12.829C126.026 12.6623 126.043 12.4108 126.21 12.2659C126.988 11.5872 128.024 11.2971 129.043 11.477Z"
          fill="#808080"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="74.9096"
          y1="21.8832"
          x2="74.9096"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4CA60" />
          <stop offset="1" stopColor="#74AF0D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="129.005"
          y1="21.8832"
          x2="129.005"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD459" />
          <stop offset="1" stopColor="#FFC000" />
        </linearGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(128.871 6.77452) scale(6.27166)"
        >
          <stop stopColor="#FFF7E2" />
          <stop offset="1" stopColor="#FFC000" />
        </radialGradient>
        <linearGradient
          id="paint3_linear"
          x1="128.209"
          y1="16.047"
          x2="134.339"
          y2="0.321107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4F1" />
          <stop offset="1" stopColor="#FD947F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="129.024"
          y1="21.8832"
          x2="129.024"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2551" stopColor="white" />
          <stop offset="1" stopColor="#FFBDAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="167.998"
          y1="21.8832"
          x2="167.998"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F36851" />
          <stop offset="1" stopColor="#D60812" />
        </linearGradient>
        <radialGradient
          id="paint6_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(167.864 6.77452) scale(6.27154)"
        >
          <stop stopColor="#FFE3DC" />
          <stop offset="1" stopColor="#D60812" />
        </radialGradient>
        <linearGradient
          id="paint7_linear"
          x1="167.202"
          y1="16.0471"
          x2="173.332"
          y2="0.320993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" />
          <stop offset="1" stopColor="#B5B5B5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="168.016"
          y1="21.8832"
          x2="168.016"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2551" stopColor="white" />
          <stop offset="1" stopColor="#BFBFBF" />
        </linearGradient>
        <radialGradient
          id="paint9_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(74.7763 6.77452) scale(6.27144)"
        >
          <stop stopColor="#F0F5E2" />
          <stop offset="1" stopColor="#74AF0D" />
        </radialGradient>
        <linearGradient
          id="paint10_linear"
          x1="74.1157"
          y1="16.047"
          x2="80.246"
          y2="0.321229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" />
          <stop offset="1" stopColor="#B3B3B3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="74.9106"
          y1="21.8832"
          x2="74.9106"
          y2="1.89559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2551" stopColor="white" />
          <stop offset="1" stopColor="#BFBFBF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default withStyles(styles)(LogoIcon);
