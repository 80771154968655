export interface MemberDTO {
  id: string;
  name: string;
  email: string;
}

export default class Member {
  id: string;
  name: string;
  email: string;

  constructor(dto: Partial<MemberDTO>) {
    this.update(dto);
  }

  get asJson(): MemberDTO {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
    };
  }

  update(newData: Partial<MemberDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
