import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      padding: spacing(3, 0),
      minHeight: 100,
    },
    icon: {
      color: colors.grey,
    },
    heading: {
      fontSize: 24,
      margin: spacing(0, 0, 1),
      textAlign: 'center',
      color: colors.grey,
    },
    subheading: {
      margin: 0,
      fontSize: 13,
      textAlign: 'center',
      color: colors.greyLight2,
    },
    button: {
      marginTop: spacing(3),
    },
  });
}
