import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    root: {
      height: '100%',
      minHeight: 400,
      maxHeight: '80vh',
    },
    heading: {},
  });
