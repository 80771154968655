import { action, computed, observable } from 'mobx';
import isEqual from 'lodash/isEqual';

import { Id } from '@shared/types/common';
import { generateId } from '@shared/utils/common';

import SurveyQuestionOption, { SurveyQuestionOptionDTO } from './option';

export enum SurveyQuestionType {
  audio = 'audio',
  dateTime = 'datetime',
  dropdown = 'dropdown',
  multipleAnswer = 'multiple_answer',
  openEnded = 'open_ended',
  photo = 'photo',
  singleAnswer = 'single_answer',
  video = 'video',
  pdf = 'pdf',
}

export type SurveyQuestionTypeWithOptions =
  | SurveyQuestionType.dropdown
  | SurveyQuestionType.multipleAnswer
  | SurveyQuestionType.singleAnswer;

export const SURVEY_QUESTION_TYPES_WITH_REQUIRED_OPTIONS = [
  SurveyQuestionType.singleAnswer,
  SurveyQuestionType.multipleAnswer,
  SurveyQuestionType.dropdown,
];

export const SURVEY_QUESTION_TYPES_WITH_OWN_ANSWER_OPTION_ABILITY = [
  SurveyQuestionType.multipleAnswer,
];

export interface SurveyQuestionDTO {
  id?: string;
  orderNumber: number;
  text: string;
  type: SurveyQuestionType;
  textAnswer: boolean;
  options?: Array<SurveyQuestionOptionDTO>;
}

export default class SurveyQuestion {
  id: string;
  @observable orderNumber: number;
  @observable text: string;
  @observable type: SurveyQuestionType;
  @observable textAnswer: boolean;
  @observable options?: Array<SurveyQuestionOption>;

  private initialData: SurveyQuestionDTO;

  constructor(dto: SurveyQuestionDTO) {
    this.initialData = dto;

    this.init(dto);
  }

  hasChanges() {
    const getDataToCompare = ({ type, text, textAnswer }: SurveyQuestionDTO) => {
      return { type, text, textAnswer };
    };

    const optionsChanged = this.options?.some((option) => option.hasChanges());
    const optionsAmountChanged = this.initialData.options?.length !== this.asJson.options?.length;
    const hasChanges =
      optionsChanged ||
      optionsAmountChanged ||
      !isEqual(getDataToCompare(this.initialData), getDataToCompare(this.asJson));

    return hasChanges;
  }

  @computed get asJson(): SurveyQuestionDTO {
    return {
      id: this.id,
      orderNumber: this.orderNumber,
      text: this.text,
      type: this.type,
      textAnswer: this.textAnswer,
      options: this.options?.map(({ asJson }) => asJson),
    };
  }

  get optionsRequired() {
    return SURVEY_QUESTION_TYPES_WITH_REQUIRED_OPTIONS.includes(this.type);
  }

  get ownAnswerAvailable() {
    return SURVEY_QUESTION_TYPES_WITH_OWN_ANSWER_OPTION_ABILITY.includes(this.type);
  }

  @action setText = (text: string) => {
    this.text = text;
  };

  @action setType = (type: SurveyQuestionType) => {
    this.type = type;
  };

  @action setOrderNumber = (orderNumber: number) => {
    this.orderNumber = orderNumber;
  };

  @action addOption = () => {
    const optionsAmount = this.options?.length || 0;

    const option = new SurveyQuestionOption({
      id: generateId(),
      text: '',
      orderNumber: optionsAmount + 1,
    });

    if (!this.options) {
      this.options = [option];

      return;
    }

    this.options.push(option);
  };

  @action deleteOption = (optionId: Id) => {
    this.options = this.options
      ?.filter(({ id }) => id != optionId)
      .map((option, i) => {
        option.setOrderNumber(i + 1);

        return option;
      });
  };

  @action deleteOptions = () => {
    this.options = [];
  };

  @action setTextAnswer = (textAnswer: boolean) => {
    this.textAnswer = textAnswer;
  };

  @action private init(newData: Partial<SurveyQuestionDTO>) {
    const { options, ...otherData } = { ...this.asJson, ...newData };

    Object.assign(this, otherData);

    this.options = options?.map((option) => new SurveyQuestionOption(option));

    return this.asJson;
  }
}
